import { useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Title } from "../../components";
import { GlobalContext } from "../../contexts/global.context";

export default function CrossBorderPaymentServicePage(){
    const {TEXTS} = useContext(GlobalContext)
    return (
        <>
            <section className="text-white relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-40">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-services.jpg" alt="" />
                <Container className="h-[550px] relative z-10 grid items-center lg:grid-cols-2">
                    <div className="flex flex-col gap-y-10">
                        <h1 className=" text-4xl md:text-5xl">{TEXTS.services[1].name}</h1>
                        <p className="text-lg">{TEXTS.services[1].description1}</p>
                        <div className="">
                            <Link to="/contact" className="w-fit group flex items-center gap-2 text-2xl">
                                <i className="group-hover:bg-secondary w-12 h-12 flex items-center justify-center border-2 border-secondary rounded-full text-xl fas fa-arrow-right" />
                                <span className="group-hover:text-primary">{TEXTS.request}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="hidden lg:flex justify-end">
                        <img className="w-96 h-96 object-cover object-center" src="/images/icashbs-payment-integration-hero.jpg" alt="" />
                    </div>
                </Container>
            </section>


            {/* what we offers starts */}
            <section id="services" className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-light.webp" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-14">
                    <Title title={TEXTS.services[1].title} description={TEXTS.services[1].description2} />
                    <div className="flex flex-wrap lg:grid lg:grid-cols-3 gap-y-8 gap-x-4">
                        {
                            TEXTS.services[1].tasks.map( (task, index) => (
                                <div key={index} className="mx-auto flex flex-col items-center gap-y-5 bg-slate-50 bg-opacity-40 shadow p-5">
                                    <img src="/images/icashbs-vsp.svg" className="rounded-full" alt="" />
                                    <h3 className="text-secondary text-center font-semibold">{task.title}</h3>
                                    <p className="text-sm text-center">{task.description}</p>
                                </div>
                            ))
                        }
                    </div>
                </Container>
            </section>
            {/* what we offers ends */}

            {/* experience starts */}
            <section className="py-14 lg:py-24 bg-white relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-0">
                {/* <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-africa.jpg" alt="" /> */}
                <Container className="h-fit relative z-10 flex flex-wrap">
                    <div className="w-full md:w-2/3 pr-5">
                        <h1 className="text-4xl text-secondary">{TEXTS.services[1].section2.title}</h1>
                        <p className="text-sm my-4">{TEXTS.services[1].section2.description}</p>
                        <div className="grid md:grid-cols-2 gap-4">
                            {
                                TEXTS.services[1].section2.tasks.map( (task, index) => (
                                    <div key={index} className="w-full bg-slate-50 bg-opacity-40 shadow p-2">
                                        <h3 className="text-sm font-semibold text-left text-secondary">{task.title}</h3>
                                        <p className="text-xs">{task.description}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="w-1/3 hidden md:flex justify-end">
                        <img className="h-full object-cover rounded-lg shadow" src="/images/icashbs-payment-integration-about.jpg" alt="" />
                    </div>
                </Container>
            </section>
            {/* experience ends */}
        </>
    )
}