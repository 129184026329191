import { Link } from "react-router-dom";
import { useEffect, useContext } from 'react';
import { GlobalContext } from "../contexts/global.context";

export default function PageNotFound() {
    const { TEXTS } = useContext(GlobalContext)
    useEffect( () => {
        document.title = `ICASH BS | ${TEXTS.pageNotFoundPageTitle}`
    }, [TEXTS.pageNotFoundPageTitle])
    return (
        <section className="relative bg-white">
            
            <div className="relative z-10 py-20 min-h-[100vh] flex flex-col items-center justify-center">                
                <img src="/images/icashbs-404.png" className="block mx-auto w-[40rem] object-contain object-center" alt="" />
                <h1 className="-mt-8 text-center text-2xl text-secondary">{TEXTS.pageNotFoundText}</h1>
                
                <Link to="/" className="px-8 py-2 text-sm text-white bg-secondary w-fit rounded-3xl mt-4">{TEXTS.home} <i className="ml-2 fas fa-home text-white" /></Link>
            </div>
        </section>
    )
};
