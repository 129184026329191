/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { Logo, Container, Button } from "."
import { GlobalContext } from "../contexts/global.context"
import Languages from "./lang.component"
import { getCurrentDate } from "../utils/date-time"

type dropdownProps = {
    label : string,
    link : string, 
    items : { label : string, link : string }[]
    isMainLink? : boolean
    md? : boolean
}
function DropdownMenuItem ({label, link, items, isMainLink, md} : dropdownProps ) {

    const id = useRef( getCurrentDate().valueOf() )
    const location = useLocation()
    const [showSubMenu, setShowSubMenu] = useState<boolean>()
    
    const onDropdownClick = useCallback( (e : React.MouseEvent<HTMLAnchorElement>) => {
        document.dispatchEvent(new CustomEvent('dropdown', { detail : id.current  })); 

        if(!isMainLink){
            e.preventDefault() 
            e.stopPropagation()
            setShowSubMenu( prev => !prev)
        }
    }, [isMainLink])

    const onDropdownHover = useCallback( () => {
        document.dispatchEvent(new CustomEvent('dropdown', { detail : id.current  }));
        setShowSubMenu( true )
    }, [])

    useEffect(() =>{
        setShowSubMenu(false)
        const onClick = () => setShowSubMenu(false)
        const onScroll = () => setShowSubMenu(false)
        const onDropdown = (e : any) => {
            if(e.detail !== id.current ){
                setShowSubMenu(false)
            }
        }

        window.addEventListener("scroll", onScroll)
        window.addEventListener("click", onClick)
        document.addEventListener( "dropdown", onDropdown)
        
        return () => {
            window.removeEventListener("scroll", onScroll)
            window.removeEventListener("click", onClick)
            document.removeEventListener("dropdown", onDropdown)
        }

    }, [location])

    return (
        <li className="relative">
            <NavLink to={link} onClick={onDropdownClick} onMouseEnter={onDropdownHover} className={ ({isActive}) => `flex items-center gap-x-2 pb-1 relative after:absolute after:-bottom-2 after:left-1/2 after:-translate-x-1/2 after:h-1 ${showSubMenu ? "after:-translate-y-0" : "after:translate-y-[1.125rem] "} ${isActive ? "after:w-full" : "after:w-0"} after:bg-secondary after:rounded-md`} >
                {label} <i className={`fas fa-angle-right ${showSubMenu ? "rotate-90":""}`} />
            </NavLink>
            <div className={`bg-slate-100 ${md ? " w-56" : "lg:w-32"} lg:absolute z-10 left-0 translate-y-3 divide-y divide-gray-300 rounded-bl rounded-br shadow-sm border-l-4 border-l-secondary lg:border-none ${showSubMenu ? 'block' : 'hidden'}`}>
                <nav className="w-full py-3 px-2 flex flex-col text-sm" aria-labelledby="our solutions">
                    {
                        items.map( (item, key) => 
                            <NavLink key={key} to={`${link}/${item.link}`} className={ ({isActive}) => `${isActive ? "font-bold" : ""} border-b last:border-b-0 border-b-secondary border-opacity-10 py-4 lg:py-1`} >
                                {item.label}
                            </NavLink>
                        )
                    }
                </nav>
            </div>
        </li>
    )
}

export default function Header(){

    const location = useLocation()
    const {TEXTS} = useContext(GlobalContext)
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    useEffect(() =>{
        setShowMobileMenu(false)

        if(location.hash){
            console.log(location.hash)
            document.getElementById(location.hash.substring(1))?.scrollIntoView({behavior : "smooth", block : "nearest" })
        }else{
            window.scrollTo( 0, 0 )
        }

        const onScroll = () => setShowMobileMenu(false)
        window.addEventListener("scroll", onScroll)
        document.body.classList.add("pt-14")

        return () => {
            document.body.classList.remove("pt-14")
            window.removeEventListener("scroll", onScroll)
        }

    }, [location])
    
    const MenuItem = useCallback( ({label, link} : {label : string, link : string}) => (
        <li>
            <NavLink to={link} className={ ({isActive}) => `pb-1 relative after:absolute after:-bottom-2 after:left-1/2 after:-translate-x-1/2 after:h-1 after:translate-y-5 ${isActive ? "after:w-full" : "after:w-0"} after:bg-secondary after:rounded-md`}>{label}</NavLink>
        </li>
    ), [])

    return (
        <header className="fixed top-0 w-full z-30 bg-slate-50 shadow">
            <Container className="flex items-center justify-between">
                <Logo className="-translate-x-5 sm:-translate-x-52 md:translate-x-0" />
                <nav className="hidden lg:flex items-center gap-10 ">
                    <ul className="list-none flex gap-6 text-secondary">
                        <MenuItem label={TEXTS.home} link="/" />
                        <MenuItem label={TEXTS.about} link="/about-us" />
                        {/* <MenuItem label="Services" link="/services" />                         */}
                        <DropdownMenuItem md label="Services" link="/services" items={TEXTS.services.map( service => ({label : service.name, link : service.slug}) )} />
                        {/* <MenuItem label="Solutions" link="/solutions" /> */}
                        <DropdownMenuItem label="Solutions" link="/solutions" items={[{label : "Yayo Africa", link : "yayo-africa"}, {label : "Duma Solutions", link : "duma-solutions"}]} />
                    </ul>
                    <Languages />
                    <Button className="" link="/contact">Contact</Button>
                </nav>

                <div className="lg:hidden flex gap-4">
                    <Languages />
                    <button className={`fas ${showMobileMenu ? "fa-close rotate-180 bg-secondary text-white translate-y-2" : "fa-bars text-secondary bg-white"}  text-3xl relative z-20 flex justify-center items-center w-10 h-10  rounded`} onClick={() => setShowMobileMenu(prev => !prev)} onBlur={ () => setShowMobileMenu(false)} />
                    <div className={`fixed z-10 top-0 bottom-0 ${showMobileMenu ? "left-0" : "-left-[200%]"} w-full text-secondary  min-h-screen flex lg:hidden flex-col gap-4 `}>
                        <div className="absolute z-10 w-full h-full bg-white" />
                        <Logo className=" -translate-x-32 translate-y-2 md:translate-x-0 relative z-10 ml-4" />
                        <nav className="list-none relative z-10 my- text-xl flex flex-col px-2 divide-y-2">
                            <NavLink 
                                to="/"
                                className={ ({isActive}) => `py-3 px-2 ${isActive ? "bg-secondary text-white" : ""}`}
                            >
                                {TEXTS.home}
                            </NavLink>
                            <NavLink 
                                to="/about-us"
                                className={ ({isActive}) => `py-3 px-2 ${isActive ? "bg-secondary text-white" : ""}`}
                            >
                                {TEXTS.about}
                            </NavLink>
                            <NavLink 
                                to="/#services"
                                className={ ({isActive}) => `py-3 px-2 ${isActive ? "bg-secondary text-white" : ""}`}
                            >
                                Services
                            </NavLink>
                            <NavLink 
                                to="/solutions/yayo-africa"
                                className={ ({isActive}) => `py-3 px-2 ${isActive ? "bg-secondary text-white" : ""}`}
                            >
                                Yayo Africa
                            </NavLink>
                            <NavLink 
                                to="/solutions/duma-solutions"
                                className={ ({isActive}) => `py-3 px-2 ${isActive ? "bg-secondary text-white" : ""}`}
                            >
                                Duma Solutions
                            </NavLink>
                            <NavLink 
                                to="/contact"
                                className={ ({isActive}) => `py-3 px-2 ${isActive ? "bg-secondary text-white" : ""}`}
                            >
                                Contact
                            </NavLink>
                        </nav>
                        <div className="relative z-10">
                            <a href="tel:+14189067326" className=" flex justify-center items-center gap-x-2">
                                <i className="fa-solid fa-headphones" />
                                <span className="">+1 418 906 7326</span>
                            </a>
                            <a href="tel:+14189067326" className="my-4 flex justify-center items-center gap-x-2">
                                <i className="fa-regular fa-envelope" />
                                <span className="">info@icashbs.com</span>
                            </a>
                            <div className="relative z-10 flex justify-center items-center gap-x-2">
                                <i className="fa-solid fa-location-dot" />
                                <span className="">625 Chateauguay, Québec, QC, Canada</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </header>
    )
}