type props = {
    children? : any
    className? : string
    style? : any
    fluid? : boolean
}
export default function Container({fluid, className, style, children} : props){

    return(
        <div className={`mx-2 ${!fluid ? " md:mx-12 lg:mx-20 xl:mx-32 2xl:mx-44" : ""} ${className}`} style={style}>
            {children}
        </div>
    )
}