import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, TeamMemberCard, Title } from "../components";
import { GlobalContext } from "../contexts/global.context";

export default function AboutUsPage(){
    const { TEXTS } = useContext(GlobalContext)

    useEffect( () => {
        document.title = `ICASH BS | ${TEXTS.about}`
    }, [TEXTS.about])
    
    return(
        <>
            <section className="py-14 lg-py-24 text-white relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-40">
                {/* <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-bg.jpg" alt="" /> */}
                <video className="absolute inset-0 h-full w-full object-cover" playsInline autoPlay muted loop poster="/images/icashbs-hero-bg.png">
                    <source src="/vids/icashbs-hero-video.mp4" type="video/webm" />
                    Your browser does not support the video tag.
                </video>
                <Container className="relative z-10 h-[300px] lg:h-[600px] flex flex-col items-center justify-center gap-5 lg:gap-y-20 drop-shadow-lg">
                    <h1 className="text-center text-2xl md:text-5xl font-semibold max-w-[50rem] 2xl:max-w-6xl">{TEXTS.aboutPageHeroText}</h1>
                    <button onClick={ _ => document.querySelector("#who-are-we")?.scrollIntoView({behavior : "smooth", block : "start", inline : "start"})} className="hover:bg-secondary w-12 h-12 flex items-center justify-center border-2 border-secondary rounded-full text-xl rotate-90 fas fa-arrow-right" />
                </Container>
            </section>

            {/* who we are starts */}
            <section id="who-are-we" className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-light.webp" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-6">
                    <Title title={TEXTS.whoWeAre.title}/>
                    <p className="mt-4 mx-auto text-center max-w-3xl text-sm sm:text-base md:text-lg 2xl:text-lg">{TEXTS.whoWeAre.p1}</p>
                    <p className="mx-auto text-center max-w-3xl text-sm sm:text-base md:text-lg 2xl:text-lg">{TEXTS.whoWeAre.p2}</p>                    
                </Container>
            </section>
            {/* who we are ends */}

            {/* vision & mission starts */}
            <section className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-about-us.jpg" alt="" />
                <Container className="relative z-10 text-white flex flex-col gap-y-8">
                    <div className="h-fit flex gap-6">
                        <div className="w-full lg:w-2/3 bg-secondary bg-opacity-80 p-5 lg:p-10 rounded-md">
                            <i className="fa-solid fa-bullseye text-6xl" />
                            <h3 className="text-3xl font-semibold">{TEXTS.vision.title}</h3>
                            <p className="mt-4 lg:text-lg">{TEXTS.vision.description}</p>
                        </div>
                        <div className="hidden lg:block w-1/3 rounded-md overflow-hidden">
                            <img src="/images/icashbs-hands-together.jpg" className="w-full h-full object-cover object-center" alt="" />

                        </div>
                    </div>
                    <div className="h-fit flex gap-6">
                        <div className="hidden lg:block w-1/3 rounded-md overflow-hidden">
                            <img src="/images/icashbs-ladies.jpg" className="w-full h-full object-cover object-center" alt="" />
                        </div>
                        <div className="w-full lg:w-2/3 bg-secondary-deep bg-opacity-60 p-5 lg:p-10 rounded-md">
                            <i className="fa-solid fa-handshake text-6xl" />
                            <h3 className="text-3xl font-semibold">{TEXTS.mission.title}</h3>
                            <p className="mt-4 lg:text-lg">{TEXTS.mission.description}</p>
                        </div>
                    </div>
                </Container>
            </section>
            {/* vision & mission ends */}

            {/* such section starts */}
            <section id="emmerj" className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-light.webp" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-6">
                    <p className="mt-4 mx-auto text-center max-w-3xl text-sm sm:text-base md:text-lg 2xl:text-lg">{TEXTS.emmerj.p1}</p>
                    <p className="mx-auto text-center max-w-3xl text-sm sm:text-base md:text-lg 2xl:text-lg">{TEXTS.emmerj.p2}</p>                    
                    <a target="_blank" href="https://forum.emmerj.com/" className="flex justify-center items-center gap-x-3 px-8 py-2 rounded-3xl w-fit mx-auto border border-primary text-primary hover:text-white hover:bg-primary " rel="noreferrer">
                        {TEXTS.emmerj.visit}
                    </a>
                </Container>
            </section>
            {/* such section ends */}

            {/* team starts */}
            <section id="team" className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-woods.jpg" alt="" />
                <Container className="text-white relative z-10 flex flex-col gap-y-6">
                    <h2 className="text-primary text-center text-4xl">{TEXTS.team.title}</h2>
                    <p className="mt-4 mx-auto text-center max-w-3xl whitespace-pre-wrap">{TEXTS.team.description}</p>

                    <div className="flex flex-wrap gap-6 justify-center">
                        <TeamMemberCard image="/images/team/icashbs-gloire-lomami.jpg" name="Gloire LOMAMI" role="Chief Executive Officer" />
                        <TeamMemberCard image="/images/team/icashbs-emmanuel-lomami.png" imagePosition="left" name="Emmanuel LOMAMI" role="Chief Technic Officer" />
                        <TeamMemberCard image="/images/team/icashbs-benjamine-lomami.jpg" name="Benjamine LOMAMI" role="YAYO Product Owner" />
                    </div>
                </Container>
            </section>
            {/* team ends */}
        </>
    )
}