
import { useCallback, useState, useEffect, useContext } from 'react';
import { MyForm, Title } from "../components";
import Container from "../components/container";

import { InputFieldTextArea } from "../components/my-form";
import { GlobalContext } from '../contexts/global.context';

export function ContactPageOld(){
    const [states, setStates] = useState<{isLoading? : boolean, error? : number}>({})
    const onSubmit = useCallback( (fields : any) => {

        console.log("sending mail ===> ", fields)

        setStates( prev => ({ ...prev, isLoading : true}))
        // axios.post("http://mail.zoun-app.com",fields)
        // .then( res => {
        //     console.log(res)
        // })
        // .catch( err => {
        //     console.log(err?.response || err)
        //     setStates( prev => ({ ...prev, error : err?.response?.statusCode || 500}))
        // })
        // .finally( () => {
        //     setStates( prev => ({ ...prev, isLoading : false}))
        // })

    }, [])

    useEffect( () => {
        document.title = "ICASH BS | Contact"
    }, [])

    return(
        <>
            
            <section className="py-14 lg-py-24 xl:py-36 relative">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-hands-together.jpg" alt="" />
                <div className="absolute inset-0 h-full w-full bg-black bg-opacity-80" />
                <Container className="relative z-10 px-3 md:px-0">
                    <Title title="Contact" />
                    <div className="text-white hidden mt-14 md:flex flex-wrap flex-row justify-center items-center gap-y-12 gap-x-6 md:gap-x-16 ">
                            <a href="tel:+14189067326" className="flex flex-col items-center gap-y-5">
                                <i className="fa-solid fa-headphones text-3xl " />
                                <span className="">+1 418 906 7326</span>
                            </a>
                            <div className="flex flex-col items-center gap-y-5">
                                <i className="fa-solid fa-location-dot text-3xl" />
                                <span className="">625 Chateauguay, Québec, QC, Canada</span>
                            </div>
                            <a href="mailto:info@icashbs.com" className="flex flex-col items-center gap-y-5">
                                <i className="fa-regular fa-envelope text-3xl" />
                                <span className="">info@icashbs.com</span>
                            </a>
                    </div>
                    <MyForm
                        className='text-white flex flex-wrap justify-between mt-8 md:mt-14 max-w-2xl mx-auto'
                        onSubmit={onSubmit}
                        fields={{
                            name : {
                                id : 'name',
                                placeholder : '',
                                label : 'Nom',
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : 'At last 10 character',
                                className : "bg-white bg-opacity-50"
                            },
                            email : {
                                id : 'email',
                                placeholder : '',
                                label : 'Email',
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : 'email is not valid',
                                className : "bg-white bg-opacity-50"                                    
                            },
                            message : {
                                label : "Message",
                                placeholder : "",
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : "too short and invalid",
                                renderFieldComponent : InputFieldTextArea,
                                className : "bg-white bg-opacity-50"
                            }

                        }}

                    >

                        <div className="flex-1 flex justify-end gap-4 my-4">
                            
                            <button 
                                className="w-full disabled:events-none flex gap-2 items-center justify-center text-center bg-primary hover:bg-primary-deep py-5 rounded-md text-white text-2xl"
                                // disabled={states.isSubmiting}
                            >
                                <span>Enregistrer</span>
                                { states.isLoading ? <span className='fa-solid fa-circle-notch animate-spin' /> : <i className="fas fa-save" />  }
                            </button>
                        </div>

                    </MyForm>
                </Container>
            </section>
        </>
    )
}


export default function ContactPage(){
    const {TEXTS} = useContext(GlobalContext)

    useEffect( () => {
        document.title = "ICASH BS | Contact"
    }, [])

    return (
        <>
            <section className="text-white relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-60">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-about-us.jpg" alt="" />
                <Container className="relative z-10 h-[150px] md:h-[300px] flex flex-col justify-center">
                    <h1 className="text-center text-4xl text-white">{TEXTS.contact.heroTitle}</h1>
                </Container>
            </section>

            <section className="py-14 lg:py-24 relative">
                <Container className="flex flex-col gap-y-10">
                    <Title title={TEXTS.contact.title} description={TEXTS.contact.description} />
                    <div className="flex flex-wrap justify-center gap-y-5 gap-x-5 md:gap-x-20 lg:gap-x-40">
                        <div className="text-secondary">
                            <h3 className="font-semibold text-center text-4xl lg:text-9xl">20</h3>
                            <p className="text-center text-xl md:text-3xl">{TEXTS.countries}</p>
                        </div>
                        <div className="text-center text-secondary">
                            <h3 className="font-semibold text-center text-4xl lg:text-9xl">8</h3>
                            <p className="text-center text-xl md:text-3xl">{TEXTS.nationalities}</p>
                        </div>
                        <div className="text-secondary">
                            <h3 className="font-semibold text-center text-4xl lg:text-9xl">3</h3>
                            <p className="text-center text-xl md:text-3xl">{TEXTS.subsidiaries}</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6">
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.paypal.com">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-paypal.webp" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://stripe.com">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-stripe.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.mtnmomo.co.za/welcome-mtn-momo">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-mtn-momo.svg" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.airtel.in/bank/products/airtel-money-wallet">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-airtel-money.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.corem.qc.ca/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-orange-money.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.vodafone.com/about-vodafone/what-we-do/consumer-products-and-services/m-pesa">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-mpesa.png" alt="" />
                        </a>
                    </div>
                </Container>
            </section>

            <section className="py-14 lg:py-24 bg-white text-secondary">
                <Container>
                    <h1 className="text-2xl lg:text-3xl font-semibold uppercase">{TEXTS.contact.secondTitle}</h1>
                    <p className="md:text-sm lg:text-lg ">{TEXTS.contact.secondDescription}</p>
                    <div className="mt-10 flex flex-col md:flex-row gap-6 lg:gap-x-10">
                        <div className="w-full md:w-1/3 lg:w-1/2">
                            <img className="w-full h-72 md:h-full object-cover object-right" src="/images/icashbs-world-map.jpg" alt="" />
                        </div>
                        <div className="w-full md:w-2/3 lg:w-1/2">
                            <h3 className="text-2xl">{TEXTS.contact.knowMore}</h3>
                            <a href="mailto:info@icashbs.com" className="text-xl">info@icashbs.com</a> 
                            <div className="my-2 flex flex-col gap-y-1">
                                <h4 className="text-xl w-fit border-b border-secondary">Canada</h4>
                                <div className="flex flex-col">
                                    <a href="tel:+14189067326">+1 418 906 7326</a>                                                                
                                    <span className="">625 Chateauguay, Québec, QC, Canada</span>
                                </div>
                            </div>
                            <div className="hidden my-2 flex-col gap-y-1">
                                <h4 className="text-xl w-fit border-b border-b-secondary">United Kingdom</h4>
                                <div className="flex flex-col">
                                    <a href="tel:+14189067326">+1 418 906 7326</a>                      
                                    <span className="">625 Chateauguay, Québec, QC, Canada</span>
                                </div>
                            </div>
                            <div className="my-2 flex flex-col gap-y-1">
                                <h4 className="text-xl w-fit border-b border-b-secondary">RD Congo</h4>
                                <div className="flex flex-col">
                                    <a href="tel:+243816122902">+243 816 122 902</a>
                                    <span className="">3 av bas-congo, Gombe/kinshasa</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </section>
            
            <section className="py-14 lg:py-24 relative after:absolute after:inset-0 after:w-full after:h-full after:bg-secondary after:bg-opacity-10 ">
                <img className="absolute inset-0 w-full h-full object-cover object-center" src="/images/icashbs-pattern-bg-dark-1.jpg" alt="" />
                <Container className="relative z-10 grid grid-cols-4 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-y-5 lg:gap-10">
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/canada.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">DRC</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/england.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">DRC</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-left rounded-full" src="/images/flags/cd.png" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">DRC</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/cg.png" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Congo</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/cam.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Cameroun</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/ivory.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Cête d'Ivoire</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/gabon.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Cête d'Ivoire</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/guin.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Guinée Konakry</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/ken.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Kenya</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/maroc.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Maroc</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/ghana.png" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Ghana</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-left rounded-full" src="/images/flags/mz.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Mozambiquue</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/ouganda.png" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Ouganda</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/burkina.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Burkina Faso</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-right rounded-full" src="/images/flags/rwanda.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Rwanda</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-right rounded-full" src="/images/flags/madagascar.gif" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Rwanda</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-right rounded-full" src="/images/flags/sierra.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Rwanda</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-right rounded-full" src="/images/flags/sn.png" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Rwanda</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-left-top rounded-full" src="/images/flags/usa.jpg" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Rwanda</span> */}
                    </div>
                    <div className="group w-fit mx-auto relative">
                        <img className="w-16 h-16 sm:w-36 sm:h-36 object-cover object-center rounded-full" src="/images/flags/bg.png" alt="" />
                        {/* <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block py-1 px-2 bg-secondary text-white text-xl font-semibold uppercase">Rwanda</span> */}
                    </div>
                </Container>
            </section>

            <section className="hidden bg-white py-14 lg:py-24 relative">
                <Container className="relative z-10 px-3 md:px-0">
                    <Title title="Contact" />
                    <div className="text-secondary mt-14 flex flex-wrap flex-row justify-center items-center gap-y-12 gap-x-6 md:gap-x-16 ">
                        <a href="tel:+14189067326" className=" max-w-[12rem] flex flex-col items-center gap-y-5">
                            <i className="fa-solid fa-headphones text-3xl " />
                            <span className="">+1 418 906 7326</span>                            
                        </a>
                        <div className=" max-w-[12rem] flex flex-col justfiy-center items-center gap-y-5">
                            <i className="fa-solid fa-location-dot text-3xl" />
                            <span className="text-center">625 Chateauguay, Québec, QC, Canada</span>                            
                        </div>
                        <a href="mailto:info@icashbs.com" className=" max-w-[12rem] flex flex-col items-center gap-y-5">
                            <i className="fa-regular fa-envelope text-3xl" />
                            <span className="">info@icashbs.com</span>                            
                        </a>
                    </div>
                </Container>
            </section>
        </>
    )
}