import { Container, Title } from "../../components";
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../contexts/global.context';

export default function YayoAfrica(){
    const {TEXTS} = useContext(GlobalContext)

    useEffect( () => {
        document.title = "ICASH BS | Yayo Africa"
    }, [])

    return (
        <>
            <section className="relative after:absolute after:inset-0 after:w-full after:h-full after:bg-purple-00 after:bg-opacity-60">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-yayo-banner.jpg" alt="" />
                <Container className="h-[550px] relative z-10 grid items-center lg:grid-cols-2">
                    <div className="flex flex-col gap-y-10">
                        <h1 className=" text-4xl md:text-5xl xl:text-7xl text-purple-900 font-bold">Yayo Africa</h1>
                        <p className="text-xl">{TEXTS.solutions.yayo.description}</p>
                        <div className="">
                            <a href="https://topup.yayo-africa.com" target="_blank" className="w-fit group flex items-center gap-2 text-2xl" rel="noreferrer">
                                <i className="text-purple-900 group-hover:text-yellow-300 group-hover:bg-purple-900 w-12 h-12 flex items-center justify-center border-2 border-purple-900 rounded-full text-xl fas fa-arrow-right" />
                                <span className="group-hover:text-purple-900">{TEXTS.emmerj.visit}</span>
                            </a>
                        </div>
                    </div>
                    <div className="hidden lg:flex justify-end">
                        <img className="w-96 h-96 object-contain object-center" src="/images/icashbs-yayo-logo-3.png" alt="" />
                    </div>
                </Container>
            </section>


            {/* how it works starts */}
            <section id="services" className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-light.webp" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-14">
                    <Title title={TEXTS.solutions.yayo.how.title} description={TEXTS.solutions.yayo.how.description} />
                    <div className="flex flex-wrap lg:grid lg:grid-cols-3 gap-y-8 gap-x-4">
                        {
                            TEXTS.solutions?.yayo?.how?.steps?.map( (step, index) => (
                                <div key={index} className="mx-auto flex flex-col items-center gap-y-5 bg-slate-50 bg-opacity-40 shadow p-5">
                                    <img src="/images/icashbs-vsp.svg" className="rounded-full" alt="" />
                                    <h3 className="text-purple-900 text-center font-semibold">{step.title}</h3>
                                    <p className="text-sm text-center">{step.description}</p>
                                </div>
                            ))
                        }
                    </div>
                </Container>
            </section>
            {/* how it works ends */}

            {/* yayo-recharge starts */}
            <section className="py-14 lg:py-24 bg-white relative after:absolute after:inset-0 after:w-full after:h-full after:bg-purple-900 after:bg-opacity-100">
                {/* <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-africa.jpg" alt="" /> */}
                <Container className="text-white h-fit relative z-10 flex flex-wrap">
                    <div className="w-full md:w-1/2 md:pr-5">
                        <h1 className="text-4xl text-yellow-300 font-bold">Yayo Recharge</h1>
                        <p className="my-4">{TEXTS.solutions.yayo.recharge.description}</p>
                        <ul className="list-none text-lg flex flex-col  gap-y-2">
                            {
                                TEXTS.solutions.yayo.recharge.stars.map( (star, index) => (
                                    <li key={index} className="flex items-center gap-2">
                                        <i className="w-6 h-6 flex justify-center items-center rounded-full bg-yellow-500 text-xs fas fa-star"/> 
                                        <span>{star}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="w-1/2 hidden md:flex justify-end">
                        <img className="h-full object-cover rounded-lg shadow" src="/images/icashbs-yayo-billing.jpg" alt="" />
                    </div>
                </Container>
            </section>
            {/* yayo-recharge ends */}

            {/* yayo-billing starts */}
            <section className="py-14 lg:py-24 bg-white relative ">
                {/* <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-africa.jpg" alt="" /> */}
                <Container className="h-fit relative z-10 flex flex-wrap">
                    <div className="w-1/2 hidden md:flex justify-end bg-yellow-500 rounded-2xl">
                        <img className="h-full object-cover rounded-lg" src="/images/icashbbs-yayo-recharge.png" alt="" />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-5">
                        <h1 className="text-4xl text-purple-900 font-bold">Yayo Billing</h1>
                        <p className="my-4">{TEXTS.solutions.yayo.billing.description}</p>
                        <ul className="list-none text-lg flex flex-col  gap-y-2">
                            {
                                TEXTS.solutions.yayo.billing.stars.map( (star, index) => (
                                    <li key={index} className="flex items-center gap-2">
                                        <i className="w-6 h-6 flex justify-center items-center rounded-full bg-purple-900 text-white text-xs fas fa-star"/>
                                        <span>{star}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </Container>
            </section>
            {/* yayo-billing ends */}

            {/*  download the app starts */}
            <section className="py-14 lg:py-24 bg-white relative after:absolute after:inset-0 after:w-full after:h-full after:bg-purple-900 after:bg-opacity-900">
                {/* <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-woods.jpg" alt="" /> */}
                <Container className="text-white h-fit flex flex-col gap-y-5 relative z-10">
                    <h1 className="text-2xl md:text-4xl text-yellow-300 text-center whitespace-pre-wrap">{TEXTS.solutions.yayo.download.title}</h1>
                    <p className="text-center font-light  md:text-xl my-4 mx-auto max-w-2xl whitespace-pre-wrap">{TEXTS.solutions.yayo.download.description}</p>
                    <div className="flex flex-wrap justify-center items-center gap-5">
                        <a href="https://apps.apple.com/ca/app/yayo-africa/id1569025775" target="_blank" rel="noreferrer">
                            <img className="w-36 h-12 object-contain" src="/images/icashbs-app-store.png" alt="" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=ca.icashbusinesssolutions.yayo" target="_blank" rel="noreferrer">
                            <img className="w-36 h-12 object-contain" src="/images/icashbs-google-play.png" alt="" />
                        </a>
                    </div>
                </Container>
            </section>
            {/* download the app ends */}
        </>
    )
}