type props = {
    title : string
    description? : string
    wrapperClassName? : string
    titleClassName? : string
    descriptionClassName? : string
}
export default function Title( {title, description, titleClassName, descriptionClassName, wrapperClassName} : props){

    return (
        <div className={`flex flex-col gap-y-8 ${wrapperClassName}`}>
            <h2 className={`text-center text-4xl text-secondary ${titleClassName || ""}`}>{title}</h2>
            { description && <p className={`mx-auto text-center max-w-3xl text-sm sm:text-base md:text-lg 2xl:text-lg ${descriptionClassName || ""}`}>{description}</p> }
        </div>
    )
}