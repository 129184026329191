type props = {
    name : string
    role : string
    image? : string
    imagePosition? : "center" | "top" | "bottom" | "left" | "right" | ""
}

export default function TeamMemberCard({name, role, image, imagePosition} : props){

    return (
        <div className="text-center text-tirtiary bg-white w-11/12 sm:w-60 pb-4 rounded-md shadow-md overflow-hidden">
            <img src={image || "/images/icashbs-bg.jpg"} className={`object-cover block h-80 sm:h-52 w-full shadow-sm bject-${imagePosition}`} alt="" />
            <h3 className="text-secondary text-2xl sm:text-xl mt-2">{name}</h3>
            <p className="text-lg sm:text-xs">{role}</p>
        </div>
    )
}