import { useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Title } from "../../components";
import { ServiceCardPreview } from "../../components/service-card.component";
import { GlobalContext } from "../../contexts/global.context";

export default function CloudAndDigitalServicePage(){
    const {TEXTS} = useContext(GlobalContext)
    return (
        <>
            <section className="text-white relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-40">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-services.jpg" alt="" />
                <Container className="py-14 lg:py-24 min-h-[550px] relative z-10 grid items-center lg:grid-cols-2">
                    <div className="flex flex-col gap-y-10">
                        <h1 className=" text-4xl md:text-5xl">{TEXTS.services[2].name}</h1>
                        <p className="text-lg">{TEXTS.services[2].description1}</p>
                        <div className="">
                            <Link to="/contact" className="w-fit group flex items-center gap-2 text-2xl">
                                <i className="group-hover:bg-secondary w-12 h-12 flex items-center justify-center border-2 border-secondary rounded-full text-xl fas fa-arrow-right" />
                                <span className="group-hover:text-primary">{TEXTS.request}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="hidden lg:flex justify-end">
                        <img className="w-96 h-96 object-cover" src="/images/icashbs-cloud-migration.jpg" alt="" />
                    </div>
                </Container>
            </section>


            {/* what we offers starts */}
            <section id="services" className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-light.webp" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-14">
                    <Title title={TEXTS.services[2].title} description={TEXTS.services[2].description2} />
                    <div className="flex flex-wrap justify-center gap-8 lg:grid lg:grid-cols-3 gap-y-8 gap-x-4">
                        {
                            TEXTS.services[2].tasks.map( (task, index) => (
                                <div key={index} className="max-w-xs mx-auto flex flex-col items-center gap-y-5 bg-slate-50 bg-opacity-40 shadow p-5">
                                    <img src="/images/icashbs-vsp.svg" className="rounded-full" alt="" />
                                    <h3 className="font-semibold text-secondary text-center">{task.title}</h3>
                                    <p className="text-sm text-center">{task.description}</p>
                                </div>
                            ))
                        }
                    </div>
                </Container>
            </section>
            {/* what we offers ends */}

            <section className="py-14 lg:py-24 bg-white">
                <Container className="mt-8 relative z-10 flex flex-col gap-y-10">                    
                    <Title title="Store management" description="Your worries about storage capacity will  be eliminated over time." />
                    <Link to="/contact" className="text-secondary mx-auto w-fit group flex items-center gap-2 ">
                        <i className="group-hover:bg-secondary group-hover:text-white w-8 h-8 flex items-center justify-center border-2 border-secondary rounded-full fas fa-arrow-right" />
                        <span className="">Contact</span>
                    </Link>
                </Container>
            </section>
        </>
    )
}