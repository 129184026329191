import {useContext} from "react"
import { GlobalContext } from "../contexts/global.context"
import { Container } from "../components"
import { ServiceCardNewLook } from "../components/service-card.component"

export default function Services2Page(){

    const {TEXTS} = useContext(GlobalContext)
    return (
        <>
            <section className="relative h-[500px] flex items-end pb-20 after:absolute after:inset-0 after:w-full after:h-full after:bg-secondary-deep after:bg-opacity-50">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-services.jpg" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-4">
                    <h1 className="text-5xl text-white font-bold uppercase">{TEXTS.ourServices.title}</h1>
                    <p className="max-w-3xl text-xl text-white font-semibold">{TEXTS.ourServices.mainDescription}</p>
                </Container>
            </section>

            <section className="py-14 lg:py-24 bg-white">
                <Container className="grid gap-y-10">
                    <p className="text-xl text-justify">{TEXTS.ourServices.secondDescription}</p>
                    <div className="p-8 bg-gray-200 flex flex-wrap gap-8">
                        <h2 className="text-2xl font-semibbold">{TEXTS.ourServices.stayInTouch} :</h2>
                        <div className="flex flex-wrap gap-y-6 gap-x-10 text-lg">
                            <div className="flex items-center gap-x-2">
                                <i className="text-3xl font-bold fas fa-envelope" />
                                <a href="mailto:info@icashbs.com">info@icashbs.com</a>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <i className="text-3xl font-bold fas fa-phone" />
                                <a href="tel:+14189067326">+1 418 906 7326</a>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="py-14 lg:py-24 bg-secondary">
                <Container className="flex flex-wrap justify-center gap-y-4">
                    { 
                        TEXTS.services.map( ({name, slug, tasks}, index) => <ServiceCardNewLook key={index} slug={slug} name={name} list={tasks.map( task => task.title)} /> )
                    }
                </Container>
            </section>

            <section className="hidden bg-white py-14 lg:py-24">
                <Container className="grid gap-y-6">
                    <h2 className="text-4xl text-semibold text-secondary">{TEXTS.ourServices.domains.title}</h2>
                    <div className="grid md:grid-cols-2 gap-y-2">
                        <div className="flex items-center gap-x-4">
                            <i className="fas fa-angle-right text-xl" />
                            <span className="text-xl">Services financiers et assurances</span>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <i className="fas fa-angle-right text-xl" />
                            <span className="text-xl">Secteur industriel</span>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <i className="fas fa-angle-right text-xl" />
                            <span className="text-xl">Secteur gouvernemental</span>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <i className="fas fa-angle-right text-xl" />
                            <span className="text-xl">Secteur manufacturier</span>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <i className="fas fa-angle-right text-xl" />
                            <span className="text-xl">Domaine de la santé</span>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <i className="fas fa-angle-right text-xl" />
                            <span className="text-xl">Domaine agroalimentaire</span>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="bg-white py-14 lg:py-24">
                <Container className="flex ">
                    <div className="w-full lg:w-4/5 flex flex-col gap-y-5 relative z-10">                        
                        <h2 className="text-4xl font-light">{TEXTS.ourServices.insuranceCenter.title}</h2>
                        <p className="text-lg text-justify  drop-shadow-lg">{TEXTS.ourServices.insuranceCenter.description}</p>
                    </div>
                    <div className="hidden w-1/5 lg:flex justify-end">
                        {/* <img className="-translate-y-48 w-full object-contain scale-150" src="/images/icashbs-banner-circle.png" alt="" /> */}
                    </div>
                </Container>
            </section>
        </>
    )
}