import { useContext } from "react"
import { Container } from "../../components"
import { ServiceCardNewLook } from "../../components/service-card.component"
import { GlobalContext } from "../../contexts/global.context"
import { Link } from "react-router-dom"

export default function ItConsulting(){
    const {TEXTS} = useContext(GlobalContext)
    return (
        <>
            <section className="relative h-[500px] flex items-center pt-10 after:absolute after:inset-0 after:w-full after:h-full after:bg-secondary-deep after:bg-opacity-50">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-services.jpg" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-4">
                    <h1 className="text-2xl md:text-5xl text-white font-bold uppercase">{TEXTS.itConsulting.title}</h1>
                    <p className="max-w-3xl md:text-xl text-white font-semibold">{TEXTS.itConsulting.mainDescription}</p>
                </Container>
            </section>

            <section className="py-14 lg:py-24 bg-white">
                <Container className="grid gap-y-10">
                    <p className="md:text-xl text-justify">{TEXTS.itConsulting.secondDescription}</p>
                    <div className="p-8 bg-gray-200 flex flex-wrap gap-8">
                        <h2 className="text-2xl font-semibbold">{TEXTS.itConsulting.stayInTouch} :</h2>
                        <div className="flex flex-wrap gap-y-6 gap-x-10 text-lg">
                            <div className="flex items-center gap-x-2">
                                <i className="text-3xl font-bold fas fa-envelope" />
                                <a href="mailto:info@icashbs.com">info@icashbs.com</a>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <i className="text-3xl font-bold fas fa-phone" />
                                <a href="tel:+14189067326">+1 418 906 7326</a>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="py-14 lg:py-24 bg-secondary">
                <Container className="grid sm:grid-cols-2 2xl:grid-cols-4 gap-8">
                    { 
                        TEXTS.itConsulting.domains.map( ({title, data}, index) => (
                            <div key={index} className="flex flex-col justify-between gap-y-4 text-white border-2 border-white">
                                <div className="grid gap-y-4 p-4">
                                    <h3 className="text-lg md:text-2xl font-semibold">{title}</h3>
                                    <ul className={`list-none grid ${data.length > 4 ? "md:grid-cols-2" : ""} gap-y-1`}>
                                        { 
                                            data.map( (item, index2) => (
                                                <li key={index2} className="flex items-center gap-2">
                                                    <i className="fas fa-check text-[.5rem] flex justify-center items-center w-4 h-4 bg-primary shadow-lg rounded-full" />
                                                    <span className="block md:text-lg">{item}</span>
                                                </li>
                                            )) 
                                        }
                                    </ul>
                                </div>
                                <Link className="bg-secondary-deep hover:bg-primary text-lg py-3 text-center" to="/contact">{TEXTS.startProject}</Link>
                            </div>
                        ) )
                    }
                </Container>
            </section>

            <section className="bg-white py-14 lg:py-24">
                <Container className="flex ">
                    <div className="w-full lg:w-4/5 flex flex-col gap-y-5 relative z-10">                        
                        <h2 className="text-2xl md:text-4xl font-semibold md:font-light">{TEXTS.itConsulting.insuranceCenter.title}</h2>
                        <p className="md:text-lg text-justify">{TEXTS.itConsulting.insuranceCenter.description}</p>
                    </div>
                    <div className="hidden w-1/5 lg:flex justify-end">
                        {/* <img className="-translate-y-48 w-full object-contain scale-150" src="/images/icashbs-banner-circle.png" alt="" /> */}
                    </div>
                </Container>
            </section>
        </>
    )
}