export default interface TextContent {
    home : string,
    about : string,
    aboutPageHeroText:  string
    keepInTouch : string,
    startProject : string,
    work : {
        title : string,
        secondTitle : string
        description : string,
    },
    hours : string,
    workTime : string
    workDays : string
    addresse : string
    location : string
    slides : {
        first : {
            title : string,
            description : string
        },
        second : {
            title : string,
            description : string
        }
    }
    ourServices : {
        title : string, 
        mainDescription : string
        secondDescription : string
        stayInTouch : string
        domains : {
            title : string
            list : string[]
        }
        insuranceCenter : {
            title : string
            description : string
        } 
    },
    itConsulting : {
        title : string, 
        mainDescription : string
        secondDescription : string
        stayInTouch : string
        domains : { title : string, data : string[] }[]
        insuranceCenter : {
            title : string
            description : string
        } 
    }
    services : [
        {
            slug : string
            name : string
            excerpt : string
            description1 : string
            description2 : string
            title : string
            tasks : { title : string, description : string} []
        },
        {
            slug : string,
            name : string,
            excerpt : string,
            description1 : string,
            description2 : string,
            title : string
            tasks : { title : string, description : string} []
            section2 : {
                title : string
                description : string
                tasks : { title : string, description : string} []
            }
        },
        {
            slug : string,
            name : string,
            excerpt :string,
            description1 : string,
            description2 : string,
            title : string
            tasks : { title : string, description : string} []
        }
    ],
    whyChooseUs : {
        title : string,
        p1 : string,
        p2 : string,
        blocks : {
            support : { title : string, excerpt : string },
            experience : { title : string, excerpt : string },
            quality : { title : string, excerpt : string },
            diversity : { title : string, excerpt : string },
        }
    },
    whoWeAre : {
        title : string,
        p1 : string,
        p2 : string
    },
    vision : {
        title : string,
        description : string
    },
    mission : {
        title : string,
        description : string
    },
    emmerj : {
        p1 : string,
        p2 : string,
        visit : string,
    },
    team : {
        title : string,
        description : string
    },
    pageNotFoundPageTitle : string,
    pageNotFoundText : string,
    request : string,
    contact : {
        heroTitle : string
        title : string
        secondTitle :string
        description : string
        secondDescription : string
        knowMore : string
    }
    countries : string
    nationalities : string,
    subsidiaries : string
    solutions : {
        yayo :{
            description : string,
            how :{
                title : string,
                description : string,
                steps : [
                    {
                        title : string,
                        description : string
                    },
                    {
                        title : string,
                        description : string
                    },
                    {
                        title : string,
                        description : string
                    }
                ]
            },
            recharge : {
                description : string,
                stars : string[]
            }
            billing : {
                description : string,
                stars : string[]
            }
            download : {
                title : string
                description : string
            }
        }    
        duma : {
            description : string,
            how :{
                title : string,
                description : string,
                steps : [
                    {
                        title : string,
                        description : string
                    },
                    {
                        title : string,
                        description : string
                    },
                    {
                        title : string,
                        description : string
                    }
                ]
            },
            cash : {
                description : string,
                stars : string[]
            }
            payme : {
                description : string,
                stars : string[]
            }
            reader : {
                title : string
                description : string
            }
        }
    },
    partners : {
        title : string
        description : string
    }
}

export const TEXT_EN : TextContent= {
    home : "Home",
    about : "About us",
    aboutPageHeroText : "Unlocking Your Digital Potential through sustainable solutions for modern businesses",
    keepInTouch : "Keep in touch",
    startProject : "Start a project",
    work : {
        title : "Our best products",
        secondTitle : "Some Works We've Done",
        description : "We've released a selection of innovative and cutting edge projects that meet the needs of our clientele while still maintaining high standards of quality.",
    },
    hours : "Official Hours",
    workTime : "9am to 5pm",
    workDays : "Monday to Friday",
    addresse : "Address",
    location : "Location",
    slides : {
        first : {
            title : "Making Africa A Market To Be Reckoned With",
            description : "Let's get you ready for the future. By using tools that cross cultural and technological barriers to better serve you, we'll help you design your digital transformation with precision."
        },
        second : {
            title : "Global provider of tailored, high-quality digital solutions for SMBs",
            description : "Diversity and technology—this is what sets us apart from all the rest. With our proven approach to problem solving and strategic planning, we will work with you to deliver an exceptional service."
        }
    },
    ourServices : {
        title : "Our Services", 
        mainDescription : "No matter how large or small our projects are, we always follow the same principles. Everything we do is built using a Research-Design-Build process that has worked time and time again.",
        secondDescription : "ICASH BUSINESS SOLUTION is a specialist in the implementation of digital solutions worldwide, providing a comprehensive range of services. Our experts are skilled in multiple disciplines, guaranteeing customers an excellent level of service for both local and international requirements.",
        domains : {
            title : "Areas and sectors of activity",
            list : []
        },
        insuranceCenter : {
            title : "Assurance Center",
            description : "Our quality assurance center is equipped with a skilled team of experts, readily available to support you with any need related to quality assurance, testing, automated testing, and piloting. Our consultants are prepared to ensure the successful deployment of your projects, in a manner that best suits your needs. Depending on your preferences, our consultants can either come to your business or provide remote support from our facility."
        },
        stayInTouch : "Contact us for any request"
    },
    itConsulting : {
        title : "IT Consultancy & Analysis", 
        mainDescription : "ICASH BUSINESS SOLUTIONS offers an extensive variety of consulting services, delivered by our team of highly-qualified personnel who provide clients with top-notch service both domestically and internationally.",
        secondDescription : "ICASH BUSINESS SOLUTION is a specialist in the implementation of digital solutions worldwide, providing a comprehensive range of services. Our experts are skilled in multiple disciplines, guaranteeing customers an excellent level of service for both local and international requirements.",
        domains : [
            { title : "Systems Development", data : ["Development", "Testing","Deployment","Maintence"] },
            { title : "System & Data security", data : [ "Encryptions","Data masking","Access Control","Data Backup"]},
            { title : "Training & Capacity Building", data : ["Productive Capacity","Protective Capacity","Idle Capacity"]},
            { title : "Project Management & Quality Assurance", data : ["Automation", "Regression testing", "Total Quality Guarantee", "Development", "Administration", "Technological"]},            
        ],
        insuranceCenter : {
            title : "Assurance Center",
            description : "Our quality assurance center is equipped with a skilled team of experts, readily available to support you with any need related to quality assurance, testing, automated testing, and piloting. Our consultants are prepared to ensure the successful deployment of your projects, in a manner that best suits your needs. Depending on your preferences, our consultants can either come to your business or provide remote support from our facility."
        },
        stayInTouch : "Contact us for any request"
    },
    services : [
        {
            slug : "it-consulting",
            name : "IT Consultancy & Analysis",
            excerpt : "We provide comprehensive IT Consultancy & Business Analysis Services to businesses of all sizes and scope",
            description1 : "With a spirited and innovative team, Icash Business Solutions offers comprehensive support to businesses during their digital transformation journey.",
            description2 : "At ICASH BUSINESS SOLUTIONS, our methodology is to specialize in a particular range of digital solutions rather than adopt a one-size-fits-all approach. Our approach to service delivery is consistent, enabling us to guarantee quality across all of our offerings. Our business strategy is built on the foundation of delivering strong, reliable, and adaptable solutions that surpass industry standards. To that end, we strive to form solid partnerships with the most reliable technology providers and our clients.",
            title : "What We Offer",
            tasks : [
                {
                    title : "Accounting and Tax management",
                    description : "Icash Business Solutions offers a full range of Taxation and Accounting services. With a combined experience in the Accounting and Taxation industry, we provide professional and affordable business services to small to medium clients including individuals, partnerships and self-managed super funds."
                },
                {
                    title : "Business Management systems",
                    description : "Achieving business gains through technology takes intention.We help you get your business where you want it to be. We start with the end in mind—your desired outcomes, and the value you hope to achieve. We translate your strategy into an executable plan that drives visibility, accountability, and focuses everyone on doing the right work"
                },
                {
                    title : "Technology",
                    description : "Our development team builds solutions that are at the leading edge of tech, keeping your digital assets effective, secure and seamlessly integrated—allowing you to connect your business to its users in innovative ways.Optimize your existing resources and put more focus on strategic IT projects while we provide quality support Our expertise in the industry puts us in a unique position to help you find the right technologies for your most complex challenges."
                },
                {
                    title : "Operation and Consultancy",
                    description : "Our ICASH Business Solutions team is here to ensure that your systems are running smoothly and efficiently. We'll work with you every step of the way, no matter how big or small your business may be.We work with you to ensure that your business can benefit from technology. We make IT work for you by providing the right solutions, planning with you in advance and adapting quickly to changing circumstances"
                }
            ]
        },
        {
            slug : "cross-border-and-integrated-payment",
            name : "Cross Border Intergrated Payments",
            excerpt : "Your payment integration partner  that offers  advanced cross-border solutions for making payments",
            description1 : "We have developed web-based and mobile cross-border payment solutions, which can me customized for any market",
            description2 : "we revolutionise the way cross-border payments are made in Africa and beyond.",
            title : "Cross border payments",
            tasks : [
                {
                    title : "Pan-African and international payments",
                    description : "Advanced technology linking African and foreign banks, payment service providers and other financial market intermediaries - enabling instant and secure payments between countries. Payments made easy."
                },
                {
                    title : "instant payments",
                    description : "Instant payments made via our digital infrastructure in local currencies, anywhere in Africa and beyond. Convenience and reliability accompany your fast transaction. With one click, we give you access to your payments."
                },
                {
                    title : "Simplified payments",
                    description : "An easy-to-use service that reduces the complexity and costs of cross-border payments in Africa, Europe, or elsewhere; while simultaneously improving operational efficiency with broader economic opportunity for all stakeholders."
                },
            ],
            section2 : {
                title : "Payment Integration",
                description : "ICASH Business Solutions positions itself as your payment integration partner, working hard to provide all the services you need. We aim to deliver advanced and state-of-the-art technology that validates the authenticity of credit cards, facilitates transaction authorisation and provides an indirect security advantage for online payments.",
                tasks : [
                    {
                        title : "secure",
                        description : "Through our certifications, we help you meet all necessary security standards such as building and maintaining a secure network system - protecting cardholder data - plus maintain a vulnerability management programme where necessary."
                    },
                    {
                        title : "Multiple currencies",
                        description : "In order to enable you remain competitive in today's global marketplace and make it easier for your customers to purchase goods or services in their own currency without having to worry about fluctuations or delays."
                    },
                    {
                        title : "Payment Intergration",
                        description : "ICASH Business Solutions positions itself as your payment integration partner, working hard to provide all the services you need. We aim to deliver advanced and state-of-the-art technology that validates the authenticity of credit cards, facilitates transaction authorisation and provides an indirect security advantage for online payments."
                    },
                    {
                        title : "Faster payments",
                        description : "ICASH Business Solutions offers one fast yet reliable way to process transactions through true real time processing with instant settlement via mobile account transfers."
                    }
                ]
            }
        },
        {
            slug : "cloud-and-digital",
            name : "Digital Transformation & Cloud Migration",
            excerpt : "ICASH Business Solutions, your trusted partner in the digital transformation of your business",
            title : "Benefits of migrating",
            description1 : "We help organisations make more effective use of the latest technology so they can thrive. We also evaluate existing technologies,and shift your business, technical and application operations to the cloud. It may seem daunting at first - but trust us when we say you'll feel comfortable throughout and leave entirely satisfied with our results.",
            description2 : "we revolutionise the way cross-border payments are made in Africa and beyond.",
            tasks : [
                {
                    title : "Unparraled felxibility",
                    description : "Cloud-based platforms that quickly handle all your bandwidth fluctuations and activity peaks challenges"
                },
                {
                    title : "store management",
                    description : "Your worries about storage capacity will be eliminated over time."
                },
                {
                    title : "cost reductions",
                    description : "Instant payments made via our digital infrastructure in local currencies, anywhere in Africa and beyond. Convenience and reliability accompany your fast transaction. With one click, we give you access to your payments."
                },
            ]
        }
    ],
    whyChooseUs : {
        title : "Why Choose Us",
        p1 : "ICASH Business Solutions is a global provider of tailored, high-quality software solutions for small and medium-sized businesses (SMBs). Every one of our team members understands the need for adaptive IT services that provide your company's staff with access to streamlined systems designed to boost productivity.",
        p2 : "We've got over 10 years' worth of experience and expertise in implementing customized software packages tailored specifically to your organization's unique needs, giving it access to enhanced functionality while simultaneously reducing downtime costs.Our commitment doesn't stop there - we're also available round-the-clock via phone or email so you'll never feel alone again when dealing with technology problems. Whatever situation arises, don't hesitate to contact us.",
        blocks : {
            support : { title : "support", excerpt : "round-the-clock assistance for any requests they may need." },
            experience : { title : "Experience", excerpt : "Extensive design and technical expertise spans across many different industries." },
            quality : { title : "Quality", excerpt : "Quality service and products that guarantee customer satisfaction and profits." },
            diversity : { title : "Diversity & Inclusion", excerpt : "Creating a diverse team that is dedicated to providing excellence." },
        }
    },
    whoWeAre : {
        title : "Who We Are",
        p1 : "ICASH Business Solutions specializes in the global implementation of digital solutions that provide personalized service and up-to-the-minute technology to satisfy the diverse needs of its customers. With a talented team, we can handle anything from start-to-finish without compromising on quality or time constraints.",
        p2 : "We are a fintech that strives for diversity and looks to bridge the gap between Africa and the rest of the world. Our mission is to empower individuals and companies through innovative technology, from mobile payments solutions to state-of-the-art IT services that support our clients' digital transformation goals"  
    },
    vision : {
        title : "Vision",
        description : "Our vision is to serve as a connector between businesses and consumers; a bridge that facilitates transformational growth across all sectors through education and change. Serving as a hub for innovantion and technological advancement in every area possible. We aims to act as an accelerator of social, political and economic growth across Africa."
    },
    mission : {
        title : "Mission",
        description : "Icash Business Solutions' mission is to provide a comprehensive range of high-quality, innovative, appropriate and cost-effective. solutions that meet the needs of its customers. and the communities it serves. We endeavor to use our expertise. to make an impactful contribution to Africa's digital revolution."
    },
    emmerj : {
        p1 : "Furthermore, we have a training center for Information Technology, New Management and Coaching of Young Entrepreneurs to Meet the Challenges of Digital Transformation in Africa.",
        p2 : "EmerJ Academy provides high-quality education to empower people who are passionate about innovations and IT from all over the world.",
        visit : "visit the website",
    },
    team : {
        title : "The ICASH-BS TEAM",
        description : "Over time, we've grown from a small 2-person startup to a global, multi-office company-with staff of differing ethnicities and skill sets. We believe in our capability to help organizations meet their goals-using the tools that we create, involve them every step of the way. \nJust some of our employees..."
    },
    pageNotFoundPageTitle : "Page not found",
    pageNotFoundText : "The page you're looking just don't exist",
    request : "Request this service",
    contact : {
        heroTitle : "Presence",
        title : "Africa is our home",
        secondTitle : "Our services cross the Atlantic",
        description : "In Europe, North America, and Africa, we maintain a broad reach through our three regional offices and an extensive network of affiliates.",
        secondDescription : "ICASH Business Solutions, provides remote development services to customers worldwide. With our specialized knowledge of business networks, our diverse skills and resources allow us to provide high-quality services that meet a range of needs. We offer projects at a fixed price and according to customer demand, while using allocated staff and specific talents.",
        knowMore : "For more information, contact us",
    },
    countries : "Countries",
    nationalities : "Nationalities",
    subsidiaries : "Subsidiaries",
    solutions : {
        yayo :{
            description : "Yayo Africa is a web and mobile application platform that allows you to pay or receive bills, top up phone credits or send money to friends and family",
            how :{
                title : "How does it work ?",
                description : "Enter the mobile number in which you want to send the top-up online.",
                steps : [
                    {
                        title : "Enter Mobile Number",
                        description : "Enter the mobile number in which you want to send the top-up online."
                    },
                    {
                        title : "Select Top-Up Amount",
                        description : "Review all top-up amounts available online and choose a mobile top-up amount to send to friends or family"
                    },
                    {
                        title : "Confirm your payment",
                        description : "Make a payment using our secure online payment process. Then, your top-up will be sent almost instantly"
                    }
                ]
            },
            recharge : {
                description : "You don't need to go anywhere, because Yayo's recharge service allows you to purchase airtime for your cellphone or that of your friends and family members. Simply pick the phone numbers and quantity desired, followed by a payment method.",
                stars : ["100% secure payments", "Quick & Efficient", "24/7 Support", "Easy to use"]
            },
            billing : {
                description : "Yayo Billing is a secure digital payment platform that permits professionals and their clients to take or send payments in an easy, clear, transparent manner. 100% integrated with financial institutions and mobile money providers makes it the most complete yet cost-effective option for businesses seeking this service.",
                stars : ["100% secure payments", "Quick & Efficient", "24/7 Support", "Easy to use", "Full integration", "Accessible"]
            },
            download : {
                title : "Download Our Yayo Africa\nMobile App Now",
                description : "Download our app for the fastest, most convenient way to\nRecharge & Bill Payment"
            }
        },
        duma : {
            description : "We focus on providing innovative solutions for SMEs and building a smart transfer finance ecosystem in Africa. Our goal is to create intelligent, sustainable solutions that drive business growth and development while also benefitting local communities. Through community partnerships in Africa, we offer the opportunity to participate in the digital revolution taking place right now!",
            how :{
                title : "Duma Solutions",
                description : "sustainable solutions that drive business growth and development while also benefitting local communities.",
                steps : [
                    {
                        title : "Duma Cash",
                        description : "A fast and reliable way to transfer money from the diaspora back home"
                    },
                    {
                        title : "Duma Pay",
                        description : "Solid digital infrastructure for receiving payments from the Diaspora"
                    },
                    {
                        title : "Duma Reader",
                        description : " A digital platform for the publication of African literary works."
                    }
                ]
            },
            cash : {
                description : "Duma Cash is a remittance platfrom  to African countries which  offers affordable international money transfer services. The system integrates with major banks and mobile money operators in order to create a reliable, secure channel for making transfers overseas. When it comes to helping your relatives financially, Duma Cash has all bases covered",
                stars : ["Available in 50+ countries", "4 international currencies", "over 1000+ trusted users", "24/7 support service"]
            },
            payme : {
                description : "Dumapay is a simple way to take and receive international payments from the diaspora. Our customizable online payment  solutions are designed to oversee all aspects of receipts and executions. Dumapay is available for various sectors including businesses, International Organizations, Non-Governmental Organizations, Embassies, Public Institutions, SMEs, etc.",
                stars : ["Payment URL", "API payment request", "Integrated payment gateway", "Customer service & support"]
            },
            reader : {
                title : "Téléchargez notre application \nMobile Yayo maintenant",
                description : "Duma Reader is a self-publishing platform for African authors and a online reading platform for people who are interested in black culture and literature. It offers resources for promoting African authors and their works. To provide non-exhaustive collections of texts in a continuous, dynamic and permanent manner."
            }
        },
    },
    partners : {
        title : "They trust us",
        description : "We've released a selection of innovative and cutting edge projects that meet the needs of our clientele while still maintaining high standards of quality.",
    },
}

export const TEXT_FR : TextContent= {
    home : "Accueil",
    about : "A propos",
    aboutPageHeroText : "Libérez votre potentiel numérique grâce à des solutions durables pour les entreprises modernes",
    keepInTouch : "Rester en contact",
    startProject : "Demarrer un project",
    work : {
        title : "Nos produits phares",
        secondTitle : "Quelques travaux réalisés",
        description : "Nous avons publié une sélection de projets innovants et avant-gardistes qui répondent aux besoins de notre clientèle tout en maintenant des normes de qualité élevées.",
    },
    hours : "Nos horaires",
    workTime : "09hr à 17hr",
    workDays : "Lundi au Vendredi",
    addresse : "Adresse",
    location : "Location",
    slides : {
        first : {
            title : "Faire de l'Afrique un marché sur lequel compter",
            description : "Préparons-nous pour l'avenir. En utilisant des outils qui franchissent les barrières culturelles et technologiques pour mieux vous servir, nous vous aiderons à concevoir votre transformation numérique avec précision."
        },
        second : {
            title : "Fournisseur mondial de solutions numériques sur mesure et de haute qualité pour les PME",
            description : "Diversité et technologie - c'est ce qui nous distingue de tous les autres. Grâce à notre approche éprouvée de la résolution de problèmes et de la planification stratégique, nous travaillerons avec vous pour vous offrir un service exceptionnel."
        }
    },
    ourServices : {
        title : "Nos Services", 
        mainDescription : "Peu importe la taille de nos projets, petits ou grands, nous suivons toujours les mêmes principes. Tout ce que nous faisons est construit à l'aide d'un processus de Recherche - Conception - Construction qui a fonctionné maintes fois.",
        secondDescription : "ICASH BUSINESS SOLUTION est un spécialiste de la mise en œuvre de solutions numériques dans le monde entier, offrant une gamme complète de services. Nos experts sont qualifiés dans de multiples disciplines, garantissant aux clients un excellent niveau de service pour les exigences locales et internationales.",
        domains : {
            title : "Domaines et secteurs d'activités",
            list : []
        },
        insuranceCenter : {
            title : "Centre d’assurance",
            description : "Notre centre d’assurance qualité est équipé d’une équipe d’experts qualifiés, facilement disponibles pour vous aider avec tout besoin lié à l’assurance qualité, aux tests, aux tests automatisés et au pilotage. Nos consultants sont prêts à assurer le déploiement réussi de vos projets, de la manière qui convient le mieux à vos besoins. Selon vos préférences, nos consultants peuvent soit venir dans votre entreprise, soit fournir une assistance à distance depuis nos installations."
        },
        stayInTouch : "Communiquez avec nous pour toute demande"
    },
    itConsulting : {
        title : "Our Services", 
        mainDescription : "No matter how large or small our projects are, we always follow the same principles. Everything we do is built using a Research-Design-Build process that has worked time and time again.",
        secondDescription : "ICASH BUSINESS SOLUTION is a specialist in the implementation of digital solutions worldwide, providing a comprehensive range of services. Our experts are skilled in multiple disciplines, guaranteeing customers an excellent level of service for both local and international requirements.",
        domains : [
            { title : "Développement de systèmes", data : ["Développement", "Test","Déploiement","Maintence"] },
            { title : "Sécurité des systèmes et des données", data : [ "Cryptage","Masquage des données","Contrôle d’accès","Sauvegarde des données"]},
            { title : "Formation et renforcement des capacités", data : ["Capacité de production","Capacité de protection","Capacité inactive"]},
            { title : "Gestion de projet et assurance qualité", data : ["Automatisation", "Tests de régression", "Garantie de qualité totale", "Développement", "Administration", "Technologique"]},            
        ],
        insuranceCenter : {
            title : "Assurance Center",
            description : "Our quality assurance center is equipped with a skilled team of experts, readily available to support you with any need related to quality assurance, testing, automated testing, and piloting. Our consultants are prepared to ensure the successful deployment of your projects, in a manner that best suits your needs. Depending on your preferences, our consultants can either come to your business or provide remote support from our facility."
        },
        stayInTouch : "Contact us for any request"
    },
    services : [
        {
            slug : "it-consulting",
            name : "Conseil et analyse informatique",
            excerpt : "Nous fournissons des services complets de conseil informatique et d'analyse commerciale aux entreprises de toutes tailles et de toutes envergures",
            description1 : "Avec une équipe fougueuse et innovante, Icash Business Solutions offre un soutien complet aux entreprises pendant leur parcours de transformation numérique.",
            description2 : "ICASH BUSINESS SOLUTIONS se spécialise dans un éventail distinct de solutions numériques, refusant d'être un touche-à-tout. Notre engagement à fournir un service impartial est le moteur de nos opérations. Notre approche des affaires implique la fourniture de services sécurisés, fiables et extensibles qui dépassent les normes de l'industrie. Pour ce faire, nous établissons des partenariats avec les fournisseurs de technologie et nos clients.",
            title : "Ce que nous offrons",
            tasks : [
                {
                    title : "Gestion comptable et fiscale",
                    description : "Icash Business Solutions offre une gamme complète de services fiscaux et comptables.Avec une expérience combinée dans le secteur de la comptabilité et de la fiscalité, nous fournissons des services professionnels et abordables aux petites et moyennes entreprises, y compris les particuliers, les partenariats et les super fonds autogérés."
                },
                {
                    title : "Systèmes de gestion d’entreprise",
                    description : "Réaliser des gains commerciaux grâce à la technologie demande de l’intention. Nous vous aidons à amener votre entreprise là où vous voulez qu’elle soit. Nous commençons avec la fin à l’esprit : les résultats souhaités et la valeur que vous espérez atteindre. Nous traduisons votre stratégie en un plan exécutable qui favorise la visibilité, la responsabilité et concentre tout le monde sur le bon travail"
                },
                {
                    title : "Technologie",
                    description : "Notre équipe de développement conçoit des solutions à la fine pointe de la technologie, assurant l’efficacité, la sécurité et l’intégration transparente de vos actifs numériques, ce qui vous permet de connecter votre entreprise à ses utilisateurs de manière innovante. Optimisez vos ressources existantes et concentrez-vous davantage sur les projets informatiques stratégiques tout en fournissant un support de qualité Notre expertise dans l’industrie nous place dans une position unique pour vous aider à trouver les bonnes technologies pour vos défis les plus complexes."
                },
                {
                    title : "Operation and Consultancy",
                    description : "Notre équipe ICASH Business Solutions est là pour s’assurer que vos systèmes fonctionnent correctement et efficacement. Nous travaillerons avec vous à chaque étape du processus, quelle que soit la taille de votre entreprise. Nous travaillons avec vous pour nous assurer que votre entreprise peut bénéficier de la technologie. Nous faisons travailler l’informatique pour vous en fournissant les bonnes solutions, en planifiant avec vous à l’avance et en nous adaptant rapidement aux circonstances changeantes"
                }
            ]
        },
        {
            slug : "cross-border-and-integrated-payment",
            name : "Paiements transfrontaliers et intégrés",
            excerpt : "Votre partenaire d'intégration de paiement qui propose des solutions transfrontalières avancées pour effectuer des paiements",
            description1 : "Nous avons développé des solutions de paiement transfrontalier basées sur le Web et mobiles, qui peuvent être personnalisées pour n’importe quel marché",
            description2 : "nous révolutionnons la façon dont les paiements transfrontaliers sont effectués en Afrique et au-delà.",
            title : "Paiements transfrontaliers",
            tasks : [
                {
                    title : "Paiements panafricains et internationaux",
                    description : "Technologie avancée reliant les banques africaines et étrangères, les prestataires de services de paiement et d’autres intermédiaires des marchés financiers - permettant des paiements instantanés et sécurisés entre les pays. Paiements simplifiés."
                },
                {
                    title : "Paiements instantanés",
                    description : "Paiements instantanés effectués via notre infrastructure numérique en devises locales, partout en Afrique et au-delà. Commodité et fiabilité accompagnent votre transaction rapide. En un clic, nous vous donnons accès à vos paiements."
                },
                {
                    title : "Paiements simplifiés",
                    description : "Un service facile à utiliser qui réduit la complexité et les coûts des paiements transfrontaliers en Afrique, en Europe ou ailleurs; tout en améliorant simultanément l’efficacité opérationnelle avec des opportunités économiques plus larges pour toutes les parties prenantes."
                },
            ],
            section2 : {
                title : "Intégration des paiements",
                description : "ICASH Business Solutions se positionne comme votre partenaire d’intégration de paiement, travaillant dur pour fournir tous les services dont vous avez besoin. Notre objectif est de fournir une technologie avancée et de pointe qui valide l’authenticité des cartes de crédit, facilite l’autorisation des transactions et offre un avantage indirect en matière de sécurité pour les paiements en ligne.",
                tasks : [
                    {
                        title : "Sûr",
                        description : "Grâce à nos certifications, nous vous aidons à respecter toutes les normes de sécurité nécessaires, telles que la création et la maintenance d’un système réseau sécurisé - protégeant les données des titulaires de carte - ainsi que le maintien d’un programme de gestion des vulnérabilités si nécessaire."
                    },
                    {
                        title : "Plusieurs devises",
                        description : "Afin de vous permettre de rester compétitif sur le marché mondial d’aujourd’hui et de permettre à vos clients d’acheter plus facilement des biens ou des services dans leur propre devise sans avoir à se soucier des fluctuations ou des retards."
                    },
                    {
                        title : "Intégration des paiements",
                        description : "ICASH Business Solutions se positionne comme votre partenaire d’intégration de paiement, travaillant dur pour fournir tous les services dont vous avez besoin. Notre objectif est de fournir une technologie avancée et de pointe qui valide l’authenticité des cartes de crédit, facilite l’autorisation des transactions et offre un avantage indirect en matière de sécurité pour les paiements en ligne."
                    },
                    {
                        title : "Paiements plus rapides",
                        description : "ICASH Business Solutions offre un moyen rapide mais fiable de traiter les transactions grâce à un véritable traitement en temps réel avec un règlement instantané via des transferts de compte mobiles."
                    }
                ]
            }
        },
        {
            slug : "cloud-and-digital",
            name : "Transformation numérique et migration vers le cloud",
            excerpt : "ICASH Business Solutions, votre partenaire de confiance dans la transformation digitale de votre entreprise",
            title : "Avantages de la migration",
            description1 : "Nous aidons les organisations à utiliser plus efficacement les dernières technologies afin qu’elles puissent prospérer. Nous évaluons également les technologies existantes et transférons vos opérations commerciales, techniques et applicatives vers le cloud. Cela peut sembler intimidant au début, mais faites-nous confiance lorsque nous disons que vous vous sentirez à l’aise tout au long et que vous repartirez entièrement satisfait de nos résultats.",
            description2 : "nous révolutionnons la façon dont les paiements transfrontaliers sont effectués en Afrique et au-delà.",
            tasks : [
                {
                    title : "Felxibilité inégalée",
                    description : "Des plates-formes basées sur le cloud qui gèrent rapidement toutes vos fluctuations de bande passante et vos pics d’activité"
                },
                {
                    title : "Gestion de magasin",
                    description : "Vos soucis concernant la capacité de stockage seront éliminés au fil du temps."
                },
                {
                    title : "Réduction des coûts",
                    description : "Paiements instantanés effectués via notre infrastructure numérique en devises locales, partout en Afrique et au-delà. Commodité et fiabilité accompagnent votre transaction rapide. En un clic, nous vous donnons accès à vos paiements."
                },
            ]
        }
    ],
    whyChooseUs : {
        title : "Pourquoi nous choisir",
        p1 : "ICASH Business Solutions est un fournisseur mondial de solutions logicielles sur mesure et de haute qualité pour les petites et moyennes entreprises (PME). Chacun des membres de notre équipe comprend le besoin de services informatiques adaptatifs qui permettent au personnel de votre entreprise d'accéder à des systèmes rationalisés conçus pour augmenter la productivité.",
        p2 : "Nous avons plus de 10 ans d'expérience et d'expertise dans la mise en œuvre de progiciels personnalisés adaptés spécifiquement aux besoins uniques de votre organisation, lui donnant accès à des fonctionnalités améliorées tout en réduisant simultanément les coûts d'indisponibilité. Notre engagement ne s'arrête pas là - nous sommes également disponible 24 heures sur 24 par téléphone ou par e-mail pour que vous ne vous sentiez plus jamais seul face à des problèmes technologiques. Quelle que soit la situation, n'hésitez pas à nous contacter.",
        blocks : {
            support : { title : "assistance", excerpt : "une assistance 24 heures sur 24 pour toutes les demandes dont ils pourraient avoir besoin." },
            experience : { title : "Expérience", excerpt : "Une vaste expertise technique et de conception s'étend à de nombreuses industries différentes." },
            quality : { title : "Qualité", excerpt : "Un service et des produits de qualité qui garantissent la satisfaction du client et les bénéfices." },
            diversity : { title : "Diversité et inclusion", excerpt : "Créer une équipe diversifiée qui se consacre à fournir l'excellence." },
        }
    },
    whoWeAre : {
        title : "Qui nous sommes",
        p1 : "ICASH Business Solutions se spécialise dans la mise en œuvre mondiale de solutions numériques qui fournissent un service personnalisé et une technologie de pointe pour satisfaire les divers besoins de ses clients. Avec une équipe talentueuse, nous pouvons gérer n'importe quoi du début à la fin sans compromettre la qualité ou les contraintes de temps.",
        p2 : "Nous sommes une fintech qui aspire à la diversité et cherche à combler le fossé entre l'Afrique et le reste du monde. Notre mission est d'autonomiser les particuliers et les entreprises grâce à une technologie innovante, des solutions de paiement mobile aux services informatiques de pointe qui soutiennent les objectifs de transformation numérique de nos clients."  
    },
    vision : {
        title : "Vision",
        description : "Notre vision est de servir de connecteur entre les entreprises et les consommateurs ; un pont qui facilite la croissance transformationnelle dans tous les secteurs grâce à l'éducation et au changement. Servir de plaque tournante pour l'innovation et l'avancement technologique dans tous les domaines possibles. Nous visons à agir comme un accélérateur de la croissance sociale, politique et économique à travers l'Afrique."
    },
    mission : {
        title : "Mission",
        description : "La mission de Icash Business Solutions est de fournir toute une gamme de services de haute qualité, innovants, appropriés et à bas coût. Les services qui rencontrent les besoins de nos clients et leurs cibles. Nous nous efforçons d'utiliser notre expertise. apporter une contribution percutante à la révolution numérique en Afrique."
    },
    emmerj : {
        p1 : "De plus, nous disposons d'un centre de formation aux technologies de l'information, au nouveau management et à l'accompagnement des jeunes entrepreneurs pour relever les défis de la transformation numérique en Afrique.",
        p2 : "EmerJ Academy offre une éducation de haute qualité pour autonomiser les personnes passionnées par les innovations et l'informatique du monde entier.",
        visit : "visitez le site web",
    },
    team : {
        title : "L'équippe ICASH-BS",
        description : "Au fil du temps, nous sommes passés d'une petite startup de 2 personnes à une entreprise mondiale à plusieurs bureaux, avec un personnel d'ethnies et de compétences différentes. Nous croyons en notre capacité à aider les organisations à atteindre leurs objectifs - en utilisant les outils que nous créons, impliquez-les à chaque étape du processus. \nQuelques-uns de nos employés..."
    },
    pageNotFoundPageTitle : "Page n'existe pas",
    pageNotFoundText : "La page recherchée n'existe pas",
    request : "Demander ce service",
    contact : {
        heroTitle : "Notre présense",
        title : "L’Afrique est notre maison",
        secondTitle : "Nos services traverssent l'antlatique",
        description : "En Europe, en Amérique du Nord, et en Afrique, nous maintenons une large portée grâce à nos trois bureaux régionaux et à un vaste réseau d'affiliés.",
        secondDescription : "ICASH Business Solutions, fournit des services de développement à distance à des clients du monde entier. Grâce à nos connaissances spécialisées en matière de réseaux d'affaires, nos diverses compétences et ressources nous permettent de fournir des services de qualité supérieure qui répondent à un éventail de besoins. Nous proposons des projets à prix fixe et en fonction de la demande des clients, tout en utilisant le personnel alloué et les talents spécifiques.",
        knowMore : "Pour en savoir, communiquez avec nous",
    },
    countries : "Pays",
    nationalities : "Nationalités",
    subsidiaries : "Filiales",
    solutions : {
        yayo :{
            description : "Yayo Africa est une plateforme d’applications web et mobiles qui vous permet de payer ou de recevoir des factures, de recharger des crédits téléphoniques ou d’envoyer de l’argent à vos amis et à votre famille.",
            how :{
                title : "Comment ça marche ?",
                description : "Entrez le numéro de téléphone mobile dans lequel vous souhaitez envoyer la recharge en ligne.",
                steps : [
                    {
                        title : "Entrez le numéro de téléphone",
                        description : "Entrez le numéro de téléphone mobile dans lequel vous souhaitez envoyer la recharge en ligne."
                    },
                    {
                        title : "Sélectionnez le montant de la recharge",
                        description : "Passez en revue tous les montants de recharge disponibles en ligne et choisissez un montant de recharge mobile à envoyer à vos amis ou à votre famille."
                    },
                    {
                        title : "Confirmez votre paiement",
                        description : "Effectuez un paiement en utilisant notre processus de paiement en ligne sécurisé. Ensuite, votre recharge sera envoyée presque instantanément"
                    }
                ]
            },
            recharge : {
                description : "Vous n’avez pas besoin d’aller n’importe où, car le service de recharge de Yayo vous permet d’acheter du temps d’antenne pour votre téléphone portable ou celui de vos amis et membres de votre famille. Choisissez simplement les numéros de téléphone et la quantité souhaitée, suivis d’un mode de paiement.",
                stars : ["100% secure payments", "Quick & Efficient", "24/7 Support", "Easy to use"]
            },
            billing : {
                description : "Yayo Billing est une plateforme de paiement numérique sécurisée qui permet aux professionnels et à leurs clients de prendre ou d’envoyer des paiements de manière simple, claire et transparente. 100% intégré avec les institutions financières et les fournisseurs d’argent mobile en fait l’option la plus complète et la plus rentable pour les entreprises à la recherche de ce service.",
                stars : ["100% secure payments", "Quick & Efficient", "24/7 Support", "Easy to use", "Full integration", "Accessible"]
            },
            download : {
                title : "Téléchargez notre application \nMobile Yayo maintenant",
                description : "Téléchargez notre application pour le moyen le plus rapide et le plus pratique denRecharger et payer vos factures"
            }
        },
        duma : {
            description : "Nous nous concentrons sur la fourniture de solutions innovantes pour les PME et la construction d’un écosystème intelligent de financement de transfert en Afrique. Notre objectif est de créer des solutions intelligentes et durables qui stimulent la croissance et le développement des entreprises tout en bénéficiant aux communautés locales. Grâce à des partenariats communautaires en Afrique, nous offrons la possibilité de participer à la révolution numérique en cours dès maintenant!",
            how :{
                title : "Duma Solutions",
                description : "Des solutions durables qui stimulent la croissance et le développement des entreprises tout en bénéficiant aux communautés locales.",
                steps : [
                    {
                        title : "Duma Cash",
                        description : "Un moyen rapide et fiable de transférer de l’argent de la diaspora dans son pays d’origine"
                    },
                    {
                        title : "Duma Pay",
                        description : "Une infrastructure numérique solide pour recevoir des paiements de la diaspora"
                    },
                    {
                        title : "Duma Reader",
                        description : "A digital platform for the publication of African literary works."
                    }
                ]
            },
            cash : {
                description : "Duma Cash est une plate-forme de transfert de fonds vers les pays africains qui offre des services de transfert d’argent internationaux abordables. Le système s’intègre aux principales banques et aux opérateurs d’argent mobile afin de créer un canal fiable et sécurisé pour effectuer des transferts à l’étranger. Lorsqu’il s’agit d’aider financièrement vos proches, Duma Cash couvre toutes les bases.",
                stars : ["Disponible dans 50 pays", "4 devises internationales", "Plus de 1000 utilisateurs de confiance", "Service d’assistance 24h/24 et 7j/7"]
            },
            payme : {
                description : "Dumapay est un moyen simple de prendre et de recevoir des paiements internationaux de la diaspora. Nos solutions de paiement en ligne personnalisables sont conçues pour superviser tous les aspects des réceptions et des exécutions. Dumapay est disponible pour divers secteurs, y compris les entreprises, les organisations internationales, les organisations non gouvernementales, les ambassades, les institutions publiques, PME, etc.",
                stars : ["URL de paiement", "Demande de paiement API", "Passerelle de paiement intégrée", "Service à la clientèle et soutien"]
            },
            reader : {
                title : "Téléchargez notre application \nMobile Yayo maintenant",
                description : "Duma Reader est une plateforme d’auto-édition pour les auteurs africains et une plateforme de lecture en ligne pour les personnes intéressées par la culture et la littérature noires. Il offre des ressources pour promouvoir les auteurs africains et leurs œuvres. Fournir des collections non exhaustives de textes de manière continue, dynamique et permanente."
            }
        },
    },
    partners : {
        title : "Ils nous ont fait confiance",
        description : "We've released a selection of innovative and cutting edge projects that meet the needs of our clientele while still maintaining high standards of quality.",
    },
}