import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState, useEffect } from 'react';
import TextContent, {TEXT_EN, TEXT_FR} from './text-content';

type props = {
    lang : string,
    setLang : Dispatch<SetStateAction<string>>,
    TEXTS : TextContent
}


export const GlobalContext = createContext<props>({lang : "en", setLang : () => {}, TEXTS : TEXT_EN})
export default function GlobalContextProvider({children} : {children : ReactNode}) {
    const [lang, setLang] = useState<string>( () => localStorage.getItem("LANG") || "en" )

    const TEXTS = useMemo( () => lang === "en" ? TEXT_EN : TEXT_FR, [lang])

    useEffect( () => {
        localStorage.setItem("LANG", lang)
    }, [lang])
    
    return (
        <GlobalContext.Provider value={{lang, setLang, TEXTS}}>
            {children}
        </GlobalContext.Provider>
    )
};
