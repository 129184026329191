import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/footer.component";
import Header from "./components/header.component";
import GlobalContextProvider from "./contexts/global.context";
import AboutUsPage from "./pages/about-us.page";
import ContactPage from "./pages/contact.page";
import HomePage from "./pages/home.page";
import PageNotFound from "./pages/page-not-found";
import Services2Page from "./pages/services-2.page";
// import BusinessAnalysServicePage from "./pages/services/business-analysis.service.page";
import CloudAndDigitalServicePage from "./pages/services/cloud-and-digita.service.page";
import CrossBorderPaymentServicePage from "./pages/services/cross-border-payment.service.page";
import YayoAfrica from "./pages/solutions/yayo-africa.page";
import DumaSolutions from "./pages/solutions/duma-solutions.page";
import ItConsulting from "./pages/services/it-consulting.service.page";

export default function App() {
  return (
    <GlobalContextProvider>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="" element={ <HomePage />} /> 
          <Route path="about-us" element={ <AboutUsPage />} />
          <Route path="services" element={ <Services2Page />} />
          <Route path="services/it-consulting" element={ <ItConsulting />} />
          {/* <Route path="services/it-consulting" element={ <BusinessAnalysServicePage />} /> */}
          <Route path="services/cross-border-and-integrated-payment" element={ <CrossBorderPaymentServicePage />} />
          <Route path="services/cloud-and-digital" element={ <CloudAndDigitalServicePage />} />
          <Route path="solutions/yayo-africa" element={ <YayoAfrica />} />
          <Route path="solutions/duma-solutions" element={ <DumaSolutions />} />
          <Route path="contact" element={ <ContactPage />} />
          <Route path="*" element={ <PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </GlobalContextProvider>
  );
}

