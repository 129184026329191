type props = {
    className? : string
    title : string
    description : string
    image? : string
    link? : string
}

export default function ProductCard({link, title, description, image, className} : props){

    return(
        <div className={`relative w-80 h-52 after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-30 flex flex-col justify-end  rounded-md shadow-md overflow-hidden ${className}`}>
            
            <img src={image || "/images/icashbs-bg.jpg"} className="absolute w-full h-full object-cover object-top blur-[1px]" alt="" />
            <div className="relative z-10 py-2 px-6 text-white bg-secondary-deep bg-opacity-90">
                <a href={link} target="_blank" className="text-lg" rel="noreferrer">{title}</a>
            </div>
        </div>
    )
}