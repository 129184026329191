import { useContext } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from '../contexts/global.context';

type props = {
    slug? : string
    name : string
    description? : string
    className? : string
    titleColor? : string
    image? : string
}

export default function ServiceCard({ name, description, titleColor, className, image = "/images/icashbs-woods.jpg"} : props){
    return (
        <div id={name.toLowerCase().replaceAll(" ", "-")} className="group p-10 rounded-md shadow-sm flex odd:flex-row-reverse odd:bg-secondary odd:bg-opacity-100 bg-white bg-opacity-10 gap-5">
            <div className="w-full lg:w-2/3">
                <h3 className="text-secondary group-odd:text-white text-xl mb-2 font-semibold ">{name}</h3>
                <p className="group-odd:text-white">{description}</p>
            </div>
            <div className="hidden lg:block w-1/3 rounded-md overflow-hidden">
                <img src="/images/icashbs-hands-together.jpg" className="w-full h-full object-cover object-center" alt="" />
            </div>
        </div>
    )
}

export function ServiceCardPreview( { slug, name, description, className} : props){

    return (
        <div className="group border mx-auto sm:mx-0 w-[22rem] h-[22rem] p-4 flex flex-col gap-y-4 justify-center items-center bg-white bg-opacity-20 rounded-full">
            {/* <img src="/images/icashbs-woods.jpg" className="absolute inset-0 w-full h-full object-center object-cover rounded-full" alt="" /> */}
            <h3 className="group-hover:text-secondary text-2xl group-hover:mb-2 text-center">{name}</h3>
            <p className="text-sm text-center hidden group-hover:block truncate whitespace-pre-wrap">{description}</p>
            <Link to={`/services/${slug}`} className="text-sm hidden group-hover:flex hover:text-secondary  w-fit mx-auto mt-2 items-center gap-2 py-1 px-3 border rounded-2xl">
                <i className="fas fa-arrow-right" />
                <span>learn more</span>
            </Link>
            {/* <div className="p-4 relative z-10 h-full w-full flex flex-col justify-center items-center text-white rounded-full group-hover:bg-secondary group-hover:bg-opacity-70">
            </div> */}
        </div>
    )
}

export function ServiceCardNewLook({name, list, slug} : props & {list : string[]}){
    const {TEXTS} = useContext(GlobalContext)
    return (
        <div className="w-full md:w-80 lg:w-[22rem] flex flex-col justify-between gap-y-4 text-white border-2 border-white">
            <div className="grid gap-y-6 p-4">
                <h3 className="text-2xl md:h-24 lg:h-16 font-semibold">{name}</h3>
                <ul className="list-none grid gap-y-1">
                    { 
                        list.map( (item, index) => (
                            <li className="flex items-center gap-2">
                                <i className="fas fa-check text-[.5rem] flex justify-center items-center w-4 h-4 bg-primary shadow-lg rounded-full" />
                                <span className="block text-lg">{item}</span>
                            </li>
                        )) 
                    }
                </ul>
            </div>
            <Link className="bg-secondary-deep hover:bg-primary text-lg py-3 text-center" to={slug || ""}>{TEXTS.startProject}</Link>
        </div>
    )
}