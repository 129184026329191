import { Link } from "react-router-dom";
import { Container, ProductCard, Title } from "../components";
import { ServiceCardPreview } from "../components/service-card.component";
import Slider from "../components/slider";
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../contexts/global.context';

export default function HomePage(){
    const {TEXTS, lang} = useContext(GlobalContext)
    useEffect( () => {
        document.title = `ICASH BS | ${TEXTS.home}`
    }, [TEXTS.home])
    
    return(
        <>
            {/* hero banner starts */}        
            <section className="text-white ">
                <Slider autoplay={ { delay : 4000, disableOnInteraction : false }} loop grabCursor speed={2000}
                >
                    <div className="relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-40">
                        <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-world-map.jpg" alt="" />
                        <Container className="relative z-10 min-h-screen lg:h-[600px] flex flex-col justify-center gap-y-6 md:gap-y-16 drop-shadow-lg">
                            <h1 className={`text-primary text-4xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-semibold ${lang === "en" ? "max-w-[50rem] lg:max-w-5xl 2xl:max-w-7xl" : ""}`}>{TEXTS.slides.second.title}</h1>
                            <p className="text-xl xl:text-2xl max-w-5xl">
                                {TEXTS.slides.second.description}
                            </p>
                            <div className="">
                                <Link to="/about-us" className="w-fit group flex items-center gap-2 text-2xl">
                                    <i className="group-hover:bg-secondary w-12 h-12 flex items-center justify-center border-2 border-secondary rounded-full text-xl fas fa-arrow-right" />
                                    <span className="group-hover:text-primary">{TEXTS.about}</span>
                                </Link>
                            </div>
                        </Container>
                    </div>
                    <div className="relative after:absolute after:inset-0 after:w-full after:h-full after:bg-black after:bg-opacity-40">
                        <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-africa.jpg" alt="" />                        
                        <Container className="relative z-10 min-h-screen lg:h-[600px] flex flex-col justify-center gap-y-6 md:gap-y-16 drop-shadow-lg">
                            <h1 className={`text-primary text-4xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-semibold ${lang === "en" ? "max-w-[50rem] lg:max-w-5xl 2xl:max-w-7xl" : "max-w-5xl 2xl:max-w-7xl"}`}>{TEXTS.slides.first.title}</h1>
                            <p className="text-xl xl:text-2xl max-w-[50rem] 2xl:max-w-4xl">{TEXTS.slides.first.description}</p>
                            <div className="">
                                <Link to="/about-us" className="w-fit group flex items-center gap-2 text-2xl">
                                    <i className="group-hover:bg-secondary w-12 h-12 flex items-center justify-center border-2 border-secondary rounded-full text-xl fas fa-arrow-right" />
                                    <span className="group-hover:text-primary">{TEXTS.about}</span>
                                </Link>
                            </div>
                        </Container>

                    </div>
                </Slider>
            </section>
            {/* hero banner ends */}

            {/* services starts */}
            <section id="services" className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-light.webp" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-14">
                    <Title title={TEXTS.ourServices.title} description={TEXTS.ourServices.mainDescription} />
                    <div className="flex flex-wrap justify-between gap-y-8">
                        {
                            TEXTS.services.map( (service, key) => <ServiceCardPreview slug={service.slug} key={key} name={service.name} description={service.excerpt} />)
                        }
                    </div>
                </Container>
            </section>
            {/* services ends */}

            {/* why choose us starts */}
            <section className="relative py-14 lg:py-24 after:absolute after:inset-0 after:w-full after:h-full after:bg-secondary-deep after:bg-opacity-20">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-woods.jpg" alt="" />
                <Container className="text-white relative z-10 grid lg:grid-cols-2 items-center gap-6">
                    <div className="flex flex-col gap-y-10">
                        <h2 className="text-primary text-4xl">{TEXTS.whyChooseUs.title}</h2>
                        <p className="">{TEXTS.whyChooseUs.p1}</p>
                        <p>{TEXTS.whyChooseUs.p2}</p>

                        <Link to="/about-us" className="w-fit group flex items-center gap-2">
                            <i className="group-hover:bg-secondary group-hover:text-white w-8 h-8 flex items-center justify-center border-2 border-secondary rounded-full fas fa-arrow-right" />
                            <span className="text-primary">{TEXTS.keepInTouch}</span>
                        </Link>
                    </div>

                    <div className="flex flex-wrap gap-y-6 gap-x-1 justify-between ">
                        <div className="w-full sm:w-[48%] lg:w-52 xl:w-60 rounded-md bg-secondary bg-opacity-80 p-6">
                            <i className="mx-auto bg-white w-10 h-10 flex justify-center items-center rounded-full text-secondary fa-solid fa-handshake text-xl" />
                            <h3 className="text-white font-semibold text-center">{TEXTS.whyChooseUs.blocks.support.title}</h3>
                            <p className=" truncate whitespace-pre-wrap text-center max-h-14 text-sm text-slate-100">{TEXTS.whyChooseUs.blocks.support.excerpt}</p>
                        </div>
                        <div className="w-full sm:w-[48%] lg:w-52 xl:w-60 rounded-md bg-secondary bg-opacity-80 p-6">
                            <i className="mx-auto bg-white w-10 h-10 flex justify-center items-center rounded-full text-secondary fa-solid fa-gears text-xl" />
                            <h3 className="text-white font-semibold text-center">{TEXTS.whyChooseUs.blocks.experience.title}</h3>
                            <p className=" truncate whitespace-pre-wrap text-center max-h-14 text-sm text-slate-100">{TEXTS.whyChooseUs.blocks.experience.excerpt}</p>
                        </div>
                        <div className="w-full sm:w-[48%] lg:w-52 xl:w-60 rounded-md bg-secondary bg-opacity-80 p-6">
                            <i className="mx-auto bg-white w-10 h-10 flex justify-center items-center rounded-full text-secondary fa-solid fa-handshake text-xl" />
                            <h3 className="text-white font-semibold text-center">{TEXTS.whyChooseUs.blocks.quality.title}</h3>
                            <p className=" truncate whitespace-pre-wrap text-center max-h-14 text-sm text-slate-100">{TEXTS.whyChooseUs.blocks.quality.excerpt}</p>
                        </div>
                        <div className="w-full sm:w-[48%] lg:w-52 xl:w-60 rounded-md bg-secondary bg-opacity-80 p-6">
                            <i className="mx-auto bg-white w-10 h-10 flex justify-center items-center rounded-full text-secondary fa-solid fa-handshake text-xl" />
                            <h3 className="text-white font-semibold text-center">{TEXTS.whyChooseUs.blocks.diversity.title}</h3>
                            <p className=" truncate whitespace-pre-wrap text-center max-h-14 text-sm text-slate-100">{TEXTS.whyChooseUs.blocks.diversity.excerpt}</p>
                        </div>
                    </div>
                </Container>
            </section>
            {/* why choose us ends */}

            {/* portfolio starts */}
            <section className="relative py-14 lg:py-24">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-light.webp" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-14">
                    <Title title={TEXTS.work.title} description={TEXTS.work.description} />
                    <div className="flex flex-wrap justify-center gap-6">
                        <ProductCard link="https://topup.yayo-africa.com/" image="/images/realisations/icashbs-yayo-africa.jpeg" title="Yayo Africa" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                        <ProductCard link="https://collect.dumacash.com/" image="/images/realisations/icashbs-dumacash.jpeg" title="Duma Cash" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                        <ProductCard link="https://dumasolutions.com/payme.html" image="/images/realisations/icashbs-dumapay.png" title="DumaPay" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                    </div>
                </Container>
            </section>
            <section className="relative pt-14 pb-14 lg:pb-24 ">
                {/* <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-light.webp" alt="" /> */}
                <Container className="relative z-10 flex flex-col gap-y-14">
                    <Title title={TEXTS.work.secondTitle}/>
                    <div className="flex flex-wrap justify-center gap-6">
                        <ProductCard link="https://solidarco.dumapay.me/" image="/images/realisations/icashbs-solidarco.jpeg" title="Solidarco" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                        <ProductCard link="https://kindreams.dumapay.me/" image="/images/realisations/icashbs-kindreams.png" title="KinDreams" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                        <ProductCard link="http://secureglobalclinics.org/" image="/images/realisations/icashbs-sgc.jpeg" title="Secure Global Clinics" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                        <ProductCard link="https://cfbanque.co.uk/" image="/images/realisations/icashbs-cfbank.jpeg" title="CF Bank" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                        <ProductCard link="https://www.ifimgabon.com" image="/images/realisations/icashbs-ifimgabon.jpeg " title="IFIM Gabon" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                        <ProductCard link="https://www.studiodiway.net" image="/images/realisations/icashbs-studiodiway.jpeg" title="Studio Diway" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolorum animi quisquam natus provident ipsa quam non, culpa similique enim?" className="" />
                    </div>
                </Container>
            </section>
            {/* portfoolio ends */}

            {/* partners starts */}
            <section className="relative py-14 lg:py-24 after:absolute after:inset-0 after:h-full after:w-full after:bg-white after:bg-opacity-100">
                <img className="absolute inset-0 h-full w-full object-cover" src="/images/icashbs-pattern-bg-dark-1.jpg" alt="" />
                <Container className="relative z-10 flex flex-col gap-y-14">
                    <Title title={TEXTS.partners.title} />
                    <div className="flex flex-wrap justify-center gap-6">
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://anacolor.com/_">
                            <img className="grayscale group-hover:grayscale-0 w-40 h-14 object-contain" src="/images/partners/icashbs-anacolor.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://gcmi.ca/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/partners/icashbs-cmi-group.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://groupebaillargeon.com/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/partners/icashbs-groupe-baillargeon.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.hoza.co.mz/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/partners/icashbs-hoza.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.emmerj.com/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/partners/icashbs-emerj-forum.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.groupeliberti.com/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/partners/icashbs-liberti-group.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://mfsafrica.com/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/partners/icashbs-mfs-africa.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.corem.qc.ca/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/partners/icashbs-correm.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.paypal.com">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-paypal.webp" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://stripe.com">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-stripe.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.mtnmomo.co.za/welcome-mtn-momo">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-mtn-momo.svg" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.airtel.in/bank/products/airtel-money-wallet">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-airtel-money.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.corem.qc.ca/">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-orange-money.png" alt="" />
                        </a>
                        <a target="_blank" rel="noreferrer" className="group p-4 border border-secondary rounded-md" href="https://www.vodafone.com/about-vodafone/what-we-do/consumer-products-and-services/m-pesa">
                            <img className="grayscale group-hover:grayscale-0 w-44 h-16 object-contain" src="/images/mobile-money/icashbs-mpesa.png" alt="" />
                        </a>
                    </div>
                </Container>
            </section>
            {/* partners ends */}
        </>
    )
}