import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from '../contexts/global.context';
import { getCurrentDate } from '../utils/date-time';

export default function Languages(){
    const {lang, setLang} = useContext(GlobalContext)
    const [dropdown, setDropdown] = useState(false)
    const id = useRef( getCurrentDate().valueOf() )

    const onDropdownClick = useCallback( (e : React.MouseEvent<HTMLDivElement>) => {
        document.dispatchEvent(new CustomEvent('dropdown', { detail : id.current  })); 
        e.stopPropagation();
        setDropdown( prev => !prev)
    }, [])

    useEffect( () => {
        const onclick = () => setDropdown( false )
        const onscroll = () => setDropdown( false )
        const onDropdown = (e : any) => (e.detail !== id.current ) && setDropdown(false)

        window.addEventListener("scroll", onscroll)
        window.addEventListener("click", onclick)
        document.addEventListener( "dropdown", onDropdown)

        return () => {
            window.removeEventListener("scroll", onscroll)
            window.removeEventListener("click", onclick)
            document.removeEventListener("dropdown", onDropdown)
        }
    } ,[])

    return (
        <div className="relative text-sm font-normal text-secondary" onClick={onDropdownClick}>
            <div className="cursor-pointer px-1 flex items-center gap-2 w-10 h-10 md:w-28  border border-secondary border-opacity-10" >
                <img className="rounded-full h-8 w-8 md:h-6 md:w-6 object-cover object-center" src={`/images/flags/${lang}.jpg`} alt="" />
                <span className="hidden md:inline">{lang === "en" ? "English" : "Français"}</span>
            </div>
            <div className={`cursor-pointer absolute bg-slate-100 items-center px-1 gap-2 w-10 h-10 md:w-28  ${dropdown ? 'flex' : 'hidden'}`} onClick={ e => {setLang( lang === "en" ? "fr" : "en")  }}>
                <img className="rounded-full h-8 w-8 md:h-6 md:w-6 object-cover object-center" src={`/images/flags/${lang === "en" ? "fr" : "en"}.jpg`} alt="" />
                <span className="hidden md:inline">{lang === "en" ? "Français" : "English"}</span>
            </div>
        </div>
    )
}