import { Link, NavLink } from "react-router-dom";
import { getCurrentDate } from "../utils/date-time";
import Container from "./container";
import Logo from "./logo.component";
import { useContext } from 'react';
import { GlobalContext } from "../contexts/global.context";

export default function Footer(){
    const {TEXTS} = useContext(GlobalContext)
    return (
        <footer className="pt-10">
            <Container className="text-tirtiary pb-4 flex justify-between gap-y-6 flex-wrap">
                <div className="w-full xl:w-1/5 overflow-hidden">
                    <Logo className="mx-auto block h-32 w-80 scale-[1.7] object-cover xl:translate-y-3 xl:-translate-x-16" />
                </div>
                <div className="w-[45%] md:w-fit">
                    <h3 className="pb-2 mb-2 border-b border-secondary border-opacity-30 text-secondary uppercase">{TEXTS.hours}</h3>
                    <ul className="list-none">
                        <li>{TEXTS.workTime}</li>
                        <li>{TEXTS.workDays}</li>
                    </ul>
                </div>
                <div className="w-[45%] md:w-fit">
                    <h3 className="pb-2 mb-2 border-b border-secondary border-opacity-30 text-secondary uppercase">Contact</h3>
                    <ul className="list-none">
                        <li><a href="mailto:info@icashbs.com">info@icashbs.com</a></li>
                        <li><a href="tel:+14189067326">+1 418 906 7326</a></li>
                    </ul>
                </div>
                <div className="w-[45%] md:w-fit">
                    <h3 className="pb-2 mb-2 border-b border-secondary border-opacity-30 text-secondary uppercase">{TEXTS.addresse}</h3>
                    <p className=" leading-[1.3]">
                        625 Chateauguay,<br />
                        Québec, QC,<br />
                        Canada
                    </p>
                </div>
                <div className="w-[45%] md:w-fit">
                    <h3 className="pb-2 mb-2 border-b border-primary border-opacity-30 text-secondary uppercase">Menu</h3>
                    <ul className="list-none">
                        <li className=""><NavLink className={ ({isActive}) => `${isActive ? "text-secondary font-semibold" : ""}`} to="/">{TEXTS.home}</NavLink></li>
                        <li className=""><NavLink className={ ({isActive}) => `${isActive ? "text-secondary font-semibold" : ""}`} to="/about-us">{TEXTS.about}</NavLink></li>
                        <li className=""><NavLink className={ ({isActive}) => `${isActive ? "text-secondary font-semibold" : ""}`} to="/services">Services</NavLink></li>
                        {/* <li className=""><NavLink to="/contact">Contacts</NavLink></li> */}
                    </ul>
                </div>
                
            </Container>
            <Container className="py-4 border-t border-primary text-sm text-secondary">
                <p className="text-center">Copyright <i className="fas fa-copyright" /> {getCurrentDate().getFullYear()}. <Link className="italic hover:text-secondary-deep font-semibold" to="/">ICASH Business Solutions</Link> All rights reserved </p>
            </Container>
        </footer>
    )
}